import { FormLayout, LegacyStack, Link, OptionList, Popover, Text, TextField } from "@shopify/polaris";
import { countryFlagOptionList } from "@smartrr/shared/data/countries";
import { ISODateString } from "@smartrr/shared/entities/ISODateString";
import {
  IMailingAddress,
  IMailingAddressCreate,
  IMailingAddressJson,
  MailingAddressType,
} from "@smartrr/shared/entities/MailingAddress";
import { ISmartrrCountry } from "@smartrr/shared/interfaces/Country";
import { CountryCode } from "@smartrr/shared/shopifyGraphQL/api";
import { getCountryByCode, getProvinceByCode } from "@smartrr/shared/utils/country";
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import * as yup from "yup";

import { useTypedForm } from "@vendor-app/app/_sharedComponents/TypedForm/useTypedForm";
import { activatorButton } from "@vendor-app/app/AdminRoute/AdminCustomersRoute/libs";
import { validatePhoneNumber } from "@smartrr/shared/utils/phoneValidation";
import { useEditAddressModalErrorStore } from "../CustomerInformationCard/hooks/useEditAddressModalErrorStore";

// Helpers & Utils

interface IAddressFormProps {
  defaultFormValues?: IMailingAddressCreate | IMailingAddress | IMailingAddressJson;
  countriesList: ISmartrrCountry[];
  children?: ReactNode;

  onChange?: (mailingAddressFormValues: IMailingAddressCreate, isValid: boolean) => void;
}

export function AddressForm({ defaultFormValues, countriesList, onChange }: IAddressFormProps): JSX.Element {
  const storeUrl = useSmartrrVendorSelector(state => state.shopifyStoreData.shop);
  const { isModalOpen } = useEditAddressModalErrorStore();
  const defaultCountryCode = "US";
  const defaultCountry = countriesList.find(country => {
    const countryCode = defaultFormValues?.countryCode || defaultCountryCode;
    return country.countryCode === countryCode;
  });

  const defaultProvince =
    defaultCountry && defaultFormValues?.provinceCode
      ? defaultCountry?.provinces.find(province => province.provinceCode === defaultFormValues.provinceCode)
      : undefined;

  const { useField, useValues, validateForm, setFieldValue } = useTypedForm({
    validationSchema: createValidationSchema(countriesList),
    initialValues: {
      address1: defaultFormValues?.address1 || "",
      address2: defaultFormValues?.address2 || "",
      city: defaultFormValues?.city || "",
      country: (defaultCountry?.countryCode || CountryCode.Us) as CountryCode,
      firstName: defaultFormValues?.firstName || "",
      lastName: defaultFormValues?.lastName || "",
      province: defaultProvince?.provinceCode,
      zip: defaultFormValues?.zip || "",
      phoneCountryCode: (defaultFormValues?.phoneCountryCode ||
        defaultCountry?.countryCode ||
        defaultCountryCode) as CountryCode,
      email: "",
      phone: defaultFormValues?.phone || "",
    },
    validateOnChange: true,

    onSubmit() {
      /* NOOP */
    },
  });

  const values = useValues();

  const initialPhoneNumber = useMemo(() => {
    const phone: string = values?.phone ?? "";
    return phone.replaceAll("+", "").replaceAll(" ", "");
  }, [values?.phone]);

  const [phoneFieldValue, setPhoneFieldValue] = useState(initialPhoneNumber);

  const [selectedCountryCode, setSelectedCountryCode] = useState<CountryCode[]>([
    values.phoneCountryCode as CountryCode,
  ]);
  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback(() => {
    setPopoverActive(popoverActive => !popoverActive);
  }, []);

  const activator = activatorButton(selectedCountryCode[0], togglePopoverActive);

  const countriesListOptions = useMemo(
    () =>
      (countriesList || []).map(country => ({
        label: country.country,
        value: country.countryCode as CountryCode,
      })),
    [countriesList]
  );

  const selectedCountry = countriesList.find(country => country.countryCode === values.country);

  const provinceListOptions = useMemo(() => {
    if (selectedCountry?.provinces.length) {
      return selectedCountry.provinces.map(province => ({
        label: province.province,
        value: province.provinceCode,
      }));
    }
  }, [selectedCountry]);
  function createValidationSchema(countriesList: ISmartrrCountry[]) {
    return yup.object().shape({
      isModalOpen: yup.boolean(),
      firstName: yup.string().required("Required"),
      lastName: yup.string().required("Required"),
      address1: yup.string().required("Required"),
      address2: yup.string(),
      email: yup.string(),
      city: yup.string().required("Required"),
      country: yup.mixed<CountryCode>().oneOf(Object.values(CountryCode)),
      province: yup.string().when("country", {
        is: countryCode => !!countriesList.find(c => c.countryCode === countryCode)?.provinces.length,
        then: yup.string().required("Required"),
      }),
      zip: yup.string().when("country", {
        is: countryCode => !!countriesList.find(c => c.countryCode === countryCode)?.zipRequired,
        then: yup.string().required("Required"),
      }),
      phone: yup
        .string()
        .test("is-valid-phone-number", "Please enter a valid number", value => {
          if (!isModalOpen && !value) {
            return true;
          } else if (isModalOpen && !value) {
            return true;
          }
          return validatePhoneNumber(value!, selectedCountryCode[0]) === true;
        })
        .when("isModalOpen", {
          is: true,
          then: schema => schema.required("Please enter a valid number"),
          otherwise: schema => schema,
        }),
      phoneCountryCode: yup.mixed<CountryCode>().oneOf(Object.values(CountryCode)),
    });
  }

  const handleTextFieldChange = useCallback(
    (value: string) => {
      const numberValue = value.replaceAll(/\D/g, "");
      setPhoneFieldValue(numberValue);
      setFieldValue("phone", numberValue);
    },
    [values.country]
  );

  const { Input: FirstNameInput } = useField("firstName");
  const { Input: LastNameInput } = useField("lastName");
  const { Input: Address1Input } = useField("address1");
  const { Input: Address2Input } = useField("address2");
  const { Input: CityInput } = useField("city");
  const { Dropdown: CountrySelect } = useField("country");
  const { Dropdown: ProvinceSelect } = useField("province");
  const { Input: ZipInput } = useField("zip");

  useEffect(() => {
    if (selectedCountry?.provinces.length && !values.province) {
      setFieldValue("province", selectedCountry?.provinces[0].provinceCode);
    }
    if (!selectedCountry?.provinces.length) {
      setFieldValue("province", undefined);
    }
    if (!selectedCountry?.zipRequired && values.zip) {
      setFieldValue("zip", undefined);
    }
    validateForm().then(errors => {
      onChange &&
        onChange(
          {
            ...values,
            country: values.country ? getCountryByCode(values.country)?.country : undefined,
            countryCode: values.country,
            phoneCountryCode: selectedCountryCode[0],
            province: values.province ? getProvinceByCode(values.country, values.province)?.province : undefined,
            provinceCode: values.province,
            shopifyUpdateDate: ISODateString.toString(new Date()),
            addressType: MailingAddressType.FOR_CUSTOMER,
          },
          !Object.keys(errors).length
        );
    });
  }, [values, selectedCountry, selectedCountryCode[0]]);

  useEffect(() => {
    if (values.country !== defaultFormValues?.countryCode) {
      setSelectedCountryCode([values.country]);
    }
  }, [values.country]);

  const checkPhoneInput = (phoneFieldValue: string) => {
    const settingsUrl = `https://${storeUrl}/admin/settings/checkout`;
    if (isModalOpen && !phoneFieldValue.length) {
      return (
        <Text as="span">
          This field is required because your Shopify store requires a phone number for all customer information.
          To change this setting in Shopify, click{" "}
          <Link url={settingsUrl} target="_blank">
            here
          </Link>
          .
        </Text>
      );
    }
  };

  return (
    <FormLayout>
      <LegacyStack distribution="fillEvenly">
        <FirstNameInput
          required
          type="text"
          label="First name"
          usePolaris
          className="edit-address__first-name-input"
        />
        <LastNameInput type="text" label="Last name" usePolaris className="edit-address__last-name-input" />
      </LegacyStack>
      <Address1Input required type="text" label="Address 1" usePolaris className="edit-address__address1-input" />
      <Address2Input type="text" label="Address 2" usePolaris className="edit-address__address2-input" />

      <LegacyStack distribution="fillEvenly">
        <CityInput placeholder="City" type="text" label="City" usePolaris className="edit-address__city-input" />
        <CountrySelect
          data-test-id="edit-address__country-select"
          label="Country"
          options={countriesListOptions}
          description=""
          usePolaris
        />
      </LegacyStack>

      <LegacyStack distribution="fillEvenly">
        {selectedCountry?.provinces.length && provinceListOptions ? (
          <ProvinceSelect
            data-test-id="edit-address__province-select"
            id="edit-address__province-select"
            label="Province"
            options={provinceListOptions}
            description=""
            usePolaris
          />
        ) : null}
        {selectedCountry?.zipRequired ? (
          <ZipInput
            className="edit-address__zip-input"
            label="Zip/Postal code"
            placeholder={selectedCountry.zipPlaceholder}
            // @ts-ignore
            type="text"
            usePolaris
          />
        ) : null}
      </LegacyStack>

      <TextField
        label="Phone"
        type="text"
        value={phoneFieldValue}
        onChange={handleTextFieldChange}
        autoComplete="off"
        error={checkPhoneInput(phoneFieldValue)}
        id="address-form-phone"
        connectedRight={
          <Popover active={popoverActive} activator={activator} onClose={togglePopoverActive}>
            <OptionList
              title="Country Flag List"
              onChange={value => {
                setSelectedCountryCode(value as CountryCode[]);
                togglePopoverActive();
              }}
              options={countryFlagOptionList}
              selected={selectedCountryCode}
            />
          </Popover>
        }
      />
    </FormLayout>
  );
}
