import { countriesData } from "@smartrr/shared/data/countries";

import { CountryCode } from "../shopifyGraphQL/api";

export function getCountryByCode(countryCode: CountryCode) {
  return countriesData.find(country => country.countryCode === countryCode);
}

export function getProvinceByCode(countryCode: CountryCode, provinceCode: string) {
  return getCountryByCode(countryCode)?.provinces.find(p => p.provinceCode === provinceCode);
}
