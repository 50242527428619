import { LegacyStack, Modal } from "@shopify/polaris";
import { ISODateString } from "@smartrr/shared/entities/ISODateString";
import {
  IMailingAddress,
  IMailingAddressCreate,
  IMailingAddressJson,
  MailingAddressType,
} from "@smartrr/shared/entities/MailingAddress";
import { ISmartrrCountry } from "@smartrr/shared/interfaces/Country";
import React, { useEffect, useState } from "react";

import { AddressForm } from "@vendor-app/app/_sharedComponents/AddressForm";

interface IEditAddressModalProps {
  open: boolean;
  title: string;
  address: IMailingAddress | IMailingAddressJson | undefined | null;
  countriesList: ISmartrrCountry[] | undefined;

  closeModal: () => void;
  updateAddress: (addressFormValues: IMailingAddressCreate) => void;
}

export const EditAddressModal = ({
  open,
  title,
  address,
  countriesList,
  closeModal,
  updateAddress,
}: IEditAddressModalProps) => {
  const [addressFormValues, setAddressFormValues] = useState<IMailingAddressCreate>({
    ...address,
    shopifyUpdateDate: ISODateString.toString(new Date()),
    addressType: MailingAddressType.FOR_CUSTOMER,
  });
  const [isValid, setIsValid] = useState<boolean>(false);

  useEffect(() => {
    setAddressFormValues({
      ...address,
      shopifyUpdateDate: ISODateString.toString(new Date()),
      addressType: MailingAddressType.FOR_CUSTOMER,
    });
  }, [updateAddress]);

  return (
    <Modal
      sectioned
      open={open}
      title={title}
      onClose={closeModal}
      primaryAction={{
        id: "customer-info__edit-default-address__confirm-btn",
        content: "Update",
        onAction: () => updateAddress(addressFormValues),
        disabled: !isValid,
      }}
      secondaryActions={[
        {
          id: "customer-info__edit-default-address__cancel-btn",
          content: "Cancel",
          onAction: closeModal,
        },
      ]}
    >
      {countriesList?.length ? (
        <AddressForm
          defaultFormValues={addressFormValues}
          countriesList={countriesList}
          onChange={(values, isAddressValid) => {
            setAddressFormValues(values);
            setIsValid(isAddressValid);
          }}
        />
      ) : (
        <LegacyStack distribution="center" alignment="center">
          <p>Countries cant be loaded at the moment</p>
        </LegacyStack>
      )}
    </Modal>
  );
};
