import { ISmartrrCountry } from "../interfaces/Country";
import { CountryCode } from "../shopifyGraphQL/api";

//
// NOTE: usage on the front end should be done with a dynamic import
//

export function getCountryCodeByName(countryName: string) {
  if (countryName) {
    const foundCountry = countriesData.find(
      country => country.country.toLowerCase() === countryName.toLowerCase()
    );
    if (foundCountry) {
      return foundCountry.countryCode;
    }
  }
  return "";
}

export function getProvinceCodeByCountryAndName(countryName: string, provinceName: string) {
  if (countryName && provinceName) {
    const foundCountry = countriesData.find(
      country => country.country.toLowerCase() === countryName.toLowerCase()
    );
    if (foundCountry) {
      const foundProvince = foundCountry.provinces.find(
        province => province.province.toLowerCase() === provinceName.toLowerCase()
      );
      if (foundProvince) {
        return foundProvince.provinceCode;
      }
    }
  }
  return "";
}
export const countriesData: ISmartrrCountry[] = [
  {
    countryCode: "AF" as CountryCode,
    id: 1,
    provinceLabel: "Region",
    country: "Afghanistan",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "AX" as CountryCode,
    id: 2,
    provinceLabel: "Region",
    country: "Aland Islands",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "AL" as CountryCode,
    id: 3,
    provinceLabel: "Region",
    country: "Albania",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "DZ" as CountryCode,
    id: 4,
    provinceLabel: "Province",
    country: "Algeria",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "AD" as CountryCode,
    id: 5,
    provinceLabel: "Region",
    country: "Andorra",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "AO" as CountryCode,
    id: 6,
    provinceLabel: "Region",
    country: "Angola",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "AI" as CountryCode,
    id: 7,
    provinceLabel: "Region",
    country: "Anguilla",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "AG" as CountryCode,
    id: 8,
    provinceLabel: "Region",
    country: "Antigua And Barbuda",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "AR" as CountryCode,
    id: 9,
    provinceLabel: "Province",
    country: "Argentina",
    provinces: [
      { provinceCode: "B", id: 1964, label: "Province", province: "Buenos Aires" },
      { provinceCode: "K", id: 1973, label: "Province", province: "Catamarca" },
      { provinceCode: "H", id: 1955, label: "Province", province: "Chaco" },
      { provinceCode: "U", id: 1969, label: "Province", province: "Chubut" },
      {
        provinceCode: "C",
        id: 2853,
        label: "Province",
        province: "Ciudad Aut\u00F3noma de Buenos Aires",
      },
      { provinceCode: "W", id: 1961, label: "Province", province: "Corrientes" },
      { provinceCode: "X", id: 1954, label: "Province", province: "C\u00F3rdoba" },
      {
        provinceCode: "E",
        id: 1957,
        label: "Province",
        province: "Entre R\u00EDos",
      },
      { provinceCode: "P", id: 1962, label: "Province", province: "Formosa" },
      { provinceCode: "Y", id: 1972, label: "Province", province: "Jujuy" },
      { provinceCode: "L", id: 1968, label: "Province", province: "La Pampa" },
      { provinceCode: "F", id: 1975, label: "Province", province: "La Rioja" },
      { provinceCode: "M", id: 1956, label: "Province", province: "Mendoza" },
      { provinceCode: "N", id: 1960, label: "Province", province: "Misiones" },
      { provinceCode: "Q", id: 1967, label: "Province", province: "Neuqu\u00E9n" },
      {
        provinceCode: "R",
        id: 1963,
        label: "Province",
        province: "R\u00EDo Negro",
      },
      { provinceCode: "A", id: 1974, label: "Province", province: "Salta" },
      { provinceCode: "J", id: 1953, label: "Province", province: "San Juan" },
      { provinceCode: "D", id: 1958, label: "Province", province: "San Luis" },
      { provinceCode: "Z", id: 1965, label: "Province", province: "Santa Cruz" },
      { provinceCode: "S", id: 1976, label: "Province", province: "Santa Fe" },
      {
        provinceCode: "G",
        id: 1971,
        label: "Province",
        province: "Santiago Del Estero",
      },
      {
        provinceCode: "V",
        id: 1966,
        label: "Province",
        province: "Tierra del Fuego",
      },
      { provinceCode: "T", id: 1959, label: "Province", province: "Tucum\u00E1n" },
    ],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "AM" as CountryCode,
    id: 10,
    provinceLabel: "Region",
    country: "Armenia",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "AW" as CountryCode,
    id: 11,
    provinceLabel: "Region",
    country: "Aruba",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "AU" as CountryCode,
    id: 12,
    provinceLabel: "State/territory",
    country: "Australia",
    provinces: [
      {
        provinceCode: "ACT",
        id: 2289,
        label: "State/territory",
        province: "Australian Capital Territory",
      },
      {
        provinceCode: "NSW",
        id: 2291,
        label: "State/territory",
        province: "New South Wales",
      },
      {
        provinceCode: "NT",
        id: 2287,
        label: "State/territory",
        province: "Northern Territory",
      },
      {
        provinceCode: "QLD",
        id: 2292,
        label: "State/territory",
        province: "Queensland",
      },
      {
        provinceCode: "SA",
        id: 2294,
        label: "State/territory",
        province: "South Australia",
      },
      {
        provinceCode: "TAS",
        id: 2293,
        label: "State/territory",
        province: "Tasmania",
      },
      {
        provinceCode: "VIC",
        id: 2290,
        label: "State/territory",
        province: "Victoria",
      },
      {
        provinceCode: "WA",
        id: 2288,
        label: "State/territory",
        province: "Western Australia",
      },
    ],
    zipLabel: "Postcode",
    zipPlaceholder: "Postcode",
    zipRequired: true,
  },
  {
    countryCode: "AT" as CountryCode,
    id: 13,
    provinceLabel: "Region",
    country: "Austria",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "AZ" as CountryCode,
    id: 14,
    provinceLabel: "Region",
    country: "Azerbaijan",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "BS" as CountryCode,
    id: 15,
    provinceLabel: "Region",
    country: "Bahamas",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "BH" as CountryCode,
    id: 16,
    provinceLabel: "Region",
    country: "Bahrain",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "BD" as CountryCode,
    id: 17,
    provinceLabel: "Region",
    country: "Bangladesh",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "BB" as CountryCode,
    id: 18,
    provinceLabel: "Region",
    country: "Barbados",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "BY" as CountryCode,
    id: 19,
    provinceLabel: "Province",
    country: "Belarus",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "BE" as CountryCode,
    id: 20,
    provinceLabel: "Region",
    country: "Belgium",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "BZ" as CountryCode,
    id: 21,
    provinceLabel: "Region",
    country: "Belize",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "BJ" as CountryCode,
    id: 22,
    provinceLabel: "Region",
    country: "Benin",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "BM" as CountryCode,
    id: 23,
    provinceLabel: "Region",
    country: "Bermuda",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "BT" as CountryCode,
    id: 24,
    provinceLabel: "Region",
    country: "Bhutan",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "BO" as CountryCode,
    id: 25,
    provinceLabel: "Region",
    country: "Bolivia",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "BA" as CountryCode,
    id: 26,
    provinceLabel: "Region",
    country: "Bosnia And Herzegovina",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "BW" as CountryCode,
    id: 27,
    provinceLabel: "Region",
    country: "Botswana",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "BV" as CountryCode,
    id: 28,
    provinceLabel: "Region",
    country: "Bouvet Island",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "BR" as CountryCode,
    id: 29,
    provinceLabel: "State",
    country: "Brazil",
    provinces: [
      { provinceCode: "AC", id: 2265, label: "State", province: "Acre" },
      { provinceCode: "AL", id: 2271, label: "State", province: "Alagoas" },
      { provinceCode: "AP", id: 2262, label: "State", province: "Amap\u00E1" },
      { provinceCode: "AM", id: 2276, label: "State", province: "Amazonas" },
      { provinceCode: "BA", id: 2280, label: "State", province: "Bahia" },
      { provinceCode: "CE", id: 2267, label: "State", province: "Cear\u00E1" },
      {
        provinceCode: "DF",
        id: 2279,
        label: "State",
        province: "Distrito Federal",
      },
      {
        provinceCode: "ES",
        id: 2281,
        label: "State",
        province: "Esp\u00EDrito Santo",
      },
      { provinceCode: "GO", id: 2268, label: "State", province: "Goi\u00E1s" },
      { provinceCode: "MA", id: 2273, label: "State", province: "Maranh\u00E3o" },
      { provinceCode: "MT", id: 2269, label: "State", province: "Mato Grosso" },
      {
        provinceCode: "MS",
        id: 2264,
        label: "State",
        province: "Mato Grosso do Sul",
      },
      { provinceCode: "MG", id: 2283, label: "State", province: "Minas Gerais" },
      { provinceCode: "PR", id: 2285, label: "State", province: "Paran\u00E1" },
      { provinceCode: "PB", id: 2274, label: "State", province: "Para\u00EDba" },
      { provinceCode: "PA", id: 2270, label: "State", province: "Par\u00E1" },
      { provinceCode: "PE", id: 2266, label: "State", province: "Pernambuco" },
      { provinceCode: "PI", id: 2263, label: "State", province: "Piau\u00ED" },
      {
        provinceCode: "RN",
        id: 2260,
        label: "State",
        province: "Rio Grande do Norte",
      },
      {
        provinceCode: "RS",
        id: 2282,
        label: "State",
        province: "Rio Grande do Sul",
      },
      { provinceCode: "RJ", id: 2277, label: "State", province: "Rio de Janeiro" },
      { provinceCode: "RO", id: 2275, label: "State", province: "Rond\u00F4nia" },
      { provinceCode: "RR", id: 2286, label: "State", province: "Roraima" },
      { provinceCode: "SC", id: 2278, label: "State", province: "Santa Catarina" },
      { provinceCode: "SE", id: 2272, label: "State", province: "Sergipe" },
      { provinceCode: "SP", id: 2284, label: "State", province: "S\u00E3o Paulo" },
      { provinceCode: "TO", id: 2261, label: "State", province: "Tocantins" },
    ],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "IO" as CountryCode,
    id: 30,
    provinceLabel: "Region",
    country: "British Indian Ocean Territory",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "BN" as CountryCode,
    id: 31,
    provinceLabel: "Region",
    country: "Brunei",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "BG" as CountryCode,
    id: 32,
    provinceLabel: "Region",
    country: "Bulgaria",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "BF" as CountryCode,
    id: 33,
    provinceLabel: "Region",
    country: "Burkina Faso",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "BI" as CountryCode,
    id: 35,
    provinceLabel: "Region",
    country: "Burundi",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "KH" as CountryCode,
    id: 36,
    provinceLabel: "Region",
    country: "Cambodia",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "CA" as CountryCode,
    id: 37,
    provinceLabel: "Province",
    country: "Canada",
    provinces: [
      { provinceCode: "AB", id: 1947, label: "Province", province: "Alberta" },
      {
        provinceCode: "BC",
        id: 1941,
        label: "Province",
        province: "British Columbia",
      },
      { provinceCode: "MB", id: 1948, label: "Province", province: "Manitoba" },
      {
        provinceCode: "NB",
        id: 1950,
        label: "Province",
        province: "New Brunswick",
      },
      {
        provinceCode: "NL",
        id: 1943,
        label: "Province",
        province: "Newfoundland and Labrador",
      },
      {
        provinceCode: "NT",
        id: 1949,
        label: "Province",
        province: "Northwest Territories",
      },
      { provinceCode: "NS", id: 1945, label: "Province", province: "Nova Scotia" },
      { provinceCode: "NU", id: 1951, label: "Province", province: "Nunavut" },
      { provinceCode: "ON", id: 1944, label: "Province", province: "Ontario" },
      {
        provinceCode: "PE",
        id: 1940,
        label: "Province",
        province: "Prince Edward Island",
      },
      { provinceCode: "QC", id: 1946, label: "Province", province: "Quebec" },
      { provinceCode: "SK", id: 1942, label: "Province", province: "Saskatchewan" },
      { provinceCode: "YT", id: 1952, label: "Province", province: "Yukon" },
    ],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "CV" as CountryCode,
    id: 38,
    provinceLabel: "Region",
    country: "Cape Verde",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "KY" as CountryCode,
    id: 39,
    provinceLabel: "Region",
    country: "Cayman Islands",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "CF" as CountryCode,
    id: 40,
    provinceLabel: "Region",
    country: "Central African Republic",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "TD" as CountryCode,
    id: 41,
    provinceLabel: "Region",
    country: "Chad",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "CL" as CountryCode,
    id: 42,
    provinceLabel: "Region",
    country: "Chile",
    provinces: [
      { provinceCode: "AN", id: 2589, label: "Region", province: "Antofagasta" },
      { provinceCode: "AR", id: 2597, label: "Region", province: "Araucan\u00EDa" },
      {
        provinceCode: "AP",
        id: 2593,
        label: "Region",
        province: "Arica and Parinacota",
      },
      { provinceCode: "AT", id: 2599, label: "Region", province: "Atacama" },
      { provinceCode: "AI", id: 2595, label: "Region", province: "Ays\u00E9n" },
      { provinceCode: "BI", id: 2591, label: "Region", province: "Biob\u00EDo" },
      { provinceCode: "CO", id: 2588, label: "Region", province: "Coquimbo" },
      { provinceCode: "LL", id: 2594, label: "Region", province: "Los Lagos" },
      { provinceCode: "LR", id: 2596, label: "Region", province: "Los R\u00EDos" },
      { provinceCode: "MA", id: 2586, label: "Region", province: "Magallanes" },
      { provinceCode: "ML", id: 2592, label: "Region", province: "Maule" },
      { provinceCode: "LI", id: 2600, label: "Region", province: "O'Higgins" },
      { provinceCode: "RM", id: 2587, label: "Region", province: "Santiago" },
      {
        provinceCode: "VS",
        id: 2590,
        label: "Region",
        province: "Valpara\u00EDso",
      },
      { provinceCode: "NB", id: 2598, label: "Region", province: "\u00D1uble" },
    ],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "CN" as CountryCode,
    id: 43,
    provinceLabel: "Province",
    country: "China",
    provinces: [
      { provinceCode: "AH", id: 2702, label: "Province", province: "Anhui" },
      { provinceCode: "BJ", id: 2722, label: "Province", province: "Beijing" },
      { provinceCode: "CQ", id: 2715, label: "Province", province: "Chongqing" },
      { provinceCode: "FJ", id: 2714, label: "Province", province: "Fujian" },
      { provinceCode: "GS", id: 2707, label: "Province", province: "Gansu" },
      { provinceCode: "GD", id: 2730, label: "Province", province: "Guangdong" },
      { provinceCode: "GX", id: 2725, label: "Province", province: "Guangxi" },
      { provinceCode: "GZ", id: 2720, label: "Province", province: "Guizhou" },
      { provinceCode: "HI", id: 2709, label: "Province", province: "Hainan" },
      { provinceCode: "HE", id: 2708, label: "Province", province: "Hebei" },
      { provinceCode: "HL", id: 2713, label: "Province", province: "Heilongjiang" },
      { provinceCode: "HA", id: 2719, label: "Province", province: "Henan" },
      { provinceCode: "HB", id: 2711, label: "Province", province: "Hubei" },
      { provinceCode: "HN", id: 2724, label: "Province", province: "Hunan" },
      {
        provinceCode: "NM",
        id: 2706,
        label: "Province",
        province: "Inner Mongolia",
      },
      { provinceCode: "JS", id: 2718, label: "Province", province: "Jiangsu" },
      { provinceCode: "JX", id: 2721, label: "Province", province: "Jiangxi" },
      { provinceCode: "JL", id: 2731, label: "Province", province: "Jilin" },
      { provinceCode: "LN", id: 2729, label: "Province", province: "Liaoning" },
      { provinceCode: "NX", id: 2727, label: "Province", province: "Ningxia" },
      { provinceCode: "QH", id: 2726, label: "Province", province: "Qinghai" },
      { provinceCode: "SN", id: 2717, label: "Province", province: "Shaanxi" },
      { provinceCode: "SD", id: 2723, label: "Province", province: "Shandong" },
      { provinceCode: "SH", id: 2704, label: "Province", province: "Shanghai" },
      { provinceCode: "SX", id: 2732, label: "Province", province: "Shanxi" },
      { provinceCode: "SC", id: 2728, label: "Province", province: "Sichuan" },
      { provinceCode: "TJ", id: 2703, label: "Province", province: "Tianjin" },
      { provinceCode: "XJ", id: 2716, label: "Province", province: "Xinjiang" },
      { provinceCode: "YZ", id: 2705, label: "Province", province: "Xizang" },
      { provinceCode: "YN", id: 2710, label: "Province", province: "Yunnan" },
      { provinceCode: "ZJ", id: 2712, label: "Province", province: "Zhejiang" },
    ],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "CX" as CountryCode,
    id: 44,
    provinceLabel: "Region",
    country: "Christmas Island",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "CC" as CountryCode,
    id: 45,
    provinceLabel: "Region",
    country: "Cocos (Keeling) Islands",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "CO" as CountryCode,
    id: 46,
    provinceLabel: "Province",
    country: "Colombia",
    provinces: [
      { provinceCode: "AMA", id: 2684, label: "Province", province: "Amazonas" },
      { provinceCode: "ANT", id: 2696, label: "Province", province: "Antioquia" },
      { provinceCode: "ARA", id: 2681, label: "Province", province: "Arauca" },
      {
        provinceCode: "ATL",
        id: 2686,
        label: "Province",
        province: "Atl\u00E1ntico",
      },
      {
        provinceCode: "DC",
        id: 2694,
        label: "Province",
        province: "Bogot\u00E1, D.C.",
      },
      {
        provinceCode: "BOL",
        id: 2688,
        label: "Province",
        province: "Bol\u00EDvar",
      },
      { provinceCode: "BOY", id: 2695, label: "Province", province: "Boyac\u00E1" },
      { provinceCode: "CAL", id: 2685, label: "Province", province: "Caldas" },
      {
        provinceCode: "CAQ",
        id: 2671,
        label: "Province",
        province: "Caquet\u00E1",
      },
      { provinceCode: "CAS", id: 2700, label: "Province", province: "Casanare" },
      { provinceCode: "CAU", id: 2676, label: "Province", province: "Cauca" },
      { provinceCode: "CES", id: 2701, label: "Province", province: "Cesar" },
      { provinceCode: "CHO", id: 2687, label: "Province", province: "Choc\u00F3" },
      {
        provinceCode: "CUN",
        id: 2675,
        label: "Province",
        province: "Cundinamarca",
      },
      {
        provinceCode: "COR",
        id: 2683,
        label: "Province",
        province: "C\u00F3rdoba",
      },
      {
        provinceCode: "GUA",
        id: 2698,
        label: "Province",
        province: "Guain\u00EDa",
      },
      { provinceCode: "GUV", id: 2673, label: "Province", province: "Guaviare" },
      { provinceCode: "HUI", id: 2690, label: "Province", province: "Huila" },
      { provinceCode: "LAG", id: 2679, label: "Province", province: "La Guajira" },
      { provinceCode: "MAG", id: 2693, label: "Province", province: "Magdalena" },
      { provinceCode: "MET", id: 2699, label: "Province", province: "Meta" },
      { provinceCode: "NAR", id: 2697, label: "Province", province: "Nari\u00F1o" },
      {
        provinceCode: "NSA",
        id: 2680,
        label: "Province",
        province: "Norte de Santander",
      },
      { provinceCode: "PUT", id: 2691, label: "Province", province: "Putumayo" },
      {
        provinceCode: "QUI",
        id: 2682,
        label: "Province",
        province: "Quind\u00EDo",
      },
      { provinceCode: "RIS", id: 2672, label: "Province", province: "Risaralda" },
      {
        provinceCode: "SAP",
        id: 2674,
        label: "Province",
        province: "San Andr\u00E9s, Providencia y Santa Catalina",
      },
      { provinceCode: "SAN", id: 2670, label: "Province", province: "Santander" },
      { provinceCode: "SUC", id: 2689, label: "Province", province: "Sucre" },
      { provinceCode: "TOL", id: 2678, label: "Province", province: "Tolima" },
      {
        provinceCode: "VAC",
        id: 2692,
        label: "Province",
        province: "Valle del Cauca",
      },
      { provinceCode: "VAU", id: 2669, label: "Province", province: "Vaup\u00E9s" },
      { provinceCode: "VID", id: 2677, label: "Province", province: "Vichada" },
    ],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "KM" as CountryCode,
    id: 47,
    provinceLabel: "Region",
    country: "Comoros",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "CG" as CountryCode,
    id: 48,
    provinceLabel: "Region",
    country: "Congo",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "CD" as CountryCode,
    id: 49,
    provinceLabel: "Region",
    country: "Congo, The Democratic Republic Of The",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "CK" as CountryCode,
    id: 50,
    provinceLabel: "Region",
    country: "Cook Islands",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "CR" as CountryCode,
    id: 51,
    provinceLabel: "Region",
    country: "Costa Rica",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "HR" as CountryCode,
    id: 52,
    provinceLabel: "Region",
    country: "Croatia",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "CU" as CountryCode,
    id: 53,
    provinceLabel: "Region",
    country: "Cuba",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "CW" as CountryCode,
    id: 54,
    provinceLabel: "Region",
    country: "Cura\u00E7ao",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "CY" as CountryCode,
    id: 55,
    provinceLabel: "Region",
    country: "Cyprus",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "CZ" as CountryCode,
    id: 56,
    provinceLabel: "Region",
    country: "Czech Republic",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "CZ" as CountryCode,
    id: 999,
    provinceLabel: "Region",
    country: "Czechia",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "CI" as CountryCode,
    id: 57,
    provinceLabel: "Region",
    country: "C\u00F4te d'Ivoire",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "DK" as CountryCode,
    id: 58,
    provinceLabel: "Region",
    country: "Denmark",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "DJ" as CountryCode,
    id: 59,
    provinceLabel: "Region",
    country: "Djibouti",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "DM" as CountryCode,
    id: 60,
    provinceLabel: "Region",
    country: "Dominica",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "DO" as CountryCode,
    id: 61,
    provinceLabel: "Region",
    country: "Dominican Republic",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "EC" as CountryCode,
    id: 62,
    provinceLabel: "Region",
    country: "Ecuador",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "EG" as CountryCode,
    id: 63,
    provinceLabel: "Governorate",
    country: "Egypt",
    provinces: [
      { provinceCode: "SU", id: 2565, label: "Region", province: "6th of October" },
      { provinceCode: "SHR", id: 2567, label: "Region", province: "Al Sharqia" },
      { provinceCode: "ALX", id: 2576, label: "Region", province: "Alexandria" },
      { provinceCode: "ASN", id: 2559, label: "Region", province: "Aswan" },
      { provinceCode: "AST", id: 2560, label: "Region", province: "Asyut" },
      { provinceCode: "BH", id: 2574, label: "Region", province: "Beheira" },
      { provinceCode: "BNS", id: 2571, label: "Region", province: "Beni Suef" },
      { provinceCode: "C", id: 2577, label: "Region", province: "Cairo" },
      { provinceCode: "DK", id: 2578, label: "Region", province: "Dakahlia" },
      { provinceCode: "DT", id: 2579, label: "Region", province: "Damietta" },
      { provinceCode: "FYM", id: 2570, label: "Region", province: "Faiyum" },
      { provinceCode: "GH", id: 2563, label: "Region", province: "Gharbia" },
      { provinceCode: "GZ", id: 2572, label: "Region", province: "Giza" },
      { provinceCode: "HU", id: 2573, label: "Region", province: "Helwan" },
      { provinceCode: "IS", id: 2558, label: "Region", province: "Ismailia" },
      {
        provinceCode: "KFS",
        id: 2561,
        label: "Region",
        province: "Kafr el-Sheikh",
      },
      { provinceCode: "LX", id: 2566, label: "Region", province: "Luxor" },
      { provinceCode: "MT", id: 2556, label: "Region", province: "Matrouh" },
      { provinceCode: "MN", id: 2584, label: "Region", province: "Minya" },
      { provinceCode: "MNF", id: 2564, label: "Region", province: "Monufia" },
      { provinceCode: "WAD", id: 2575, label: "Region", province: "New Valley" },
      { provinceCode: "SIN", id: 2580, label: "Region", province: "North Sinai" },
      { provinceCode: "PTS", id: 2557, label: "Region", province: "Port Said" },
      { provinceCode: "KB", id: 2582, label: "Region", province: "Qalyubia" },
      { provinceCode: "KN", id: 2562, label: "Region", province: "Qena" },
      { provinceCode: "BA", id: 2581, label: "Region", province: "Red Sea" },
      { provinceCode: "SHG", id: 2569, label: "Region", province: "Sohag" },
      { provinceCode: "JS", id: 2583, label: "Region", province: "South Sinai" },
      { provinceCode: "SUZ", id: 2568, label: "Region", province: "Suez" },
    ],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "SV" as CountryCode,
    id: 64,
    provinceLabel: "Region",
    country: "El Salvador",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "GQ" as CountryCode,
    id: 65,
    provinceLabel: "Region",
    country: "Equatorial Guinea",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "ER" as CountryCode,
    id: 66,
    provinceLabel: "Region",
    country: "Eritrea",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "EE" as CountryCode,
    id: 67,
    provinceLabel: "Region",
    country: "Estonia",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "ET" as CountryCode,
    id: 68,
    provinceLabel: "Region",
    country: "Ethiopia",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "FK" as CountryCode,
    id: 69,
    provinceLabel: "Region",
    country: "Falkland Islands (Malvinas)",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "FO" as CountryCode,
    id: 70,
    provinceLabel: "Region",
    country: "Faroe Islands",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "FJ" as CountryCode,
    id: 71,
    provinceLabel: "Region",
    country: "Fiji",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "FI" as CountryCode,
    id: 72,
    provinceLabel: "Region",
    country: "Finland",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "FR" as CountryCode,
    id: 73,
    provinceLabel: "Region",
    country: "France",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "GF" as CountryCode,
    id: 74,
    provinceLabel: "Region",
    country: "French Guiana",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "PF" as CountryCode,
    id: 75,
    provinceLabel: "Region",
    country: "French Polynesia",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "TF" as CountryCode,
    id: 76,
    provinceLabel: "Region",
    country: "French Southern Territories",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "GA" as CountryCode,
    id: 77,
    provinceLabel: "Region",
    country: "Gabon",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "GM" as CountryCode,
    id: 78,
    provinceLabel: "Region",
    country: "Gambia",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "GE" as CountryCode,
    id: 79,
    provinceLabel: "Region",
    country: "Georgia",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "DE" as CountryCode,
    id: 80,
    provinceLabel: "Region",
    country: "Germany",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "GH" as CountryCode,
    id: 81,
    provinceLabel: "Region",
    country: "Ghana",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "GI" as CountryCode,
    id: 82,
    provinceLabel: "Region",
    country: "Gibraltar",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "GR" as CountryCode,
    id: 83,
    provinceLabel: "Province",
    country: "Greece",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "GL" as CountryCode,
    id: 84,
    provinceLabel: "Region",
    country: "Greenland",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "GD" as CountryCode,
    id: 85,
    provinceLabel: "Region",
    country: "Grenada",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "GP" as CountryCode,
    id: 86,
    provinceLabel: "Region",
    country: "Guadeloupe",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "GT" as CountryCode,
    id: 87,
    provinceLabel: "Region",
    country: "Guatemala",
    provinces: [
      { provinceCode: "AVE", id: 1980, label: "Region", province: "Alta Verapaz" },
      { provinceCode: "BVE", id: 1997, label: "Region", province: "Baja Verapaz" },
      { provinceCode: "CMT", id: 1991, label: "Region", province: "Chimaltenango" },
      { provinceCode: "CQM", id: 1992, label: "Region", province: "Chiquimula" },
      { provinceCode: "EPR", id: 1986, label: "Region", province: "El Progreso" },
      { provinceCode: "ESC", id: 1998, label: "Region", province: "Escuintla" },
      { provinceCode: "GUA", id: 1981, label: "Region", province: "Guatemala" },
      { provinceCode: "HUE", id: 1988, label: "Region", province: "Huehuetenango" },
      { provinceCode: "IZA", id: 1989, label: "Region", province: "Izabal" },
      { provinceCode: "JAL", id: 1983, label: "Region", province: "Jalapa" },
      { provinceCode: "JUT", id: 1990, label: "Region", province: "Jutiapa" },
      { provinceCode: "PET", id: 1987, label: "Region", province: "Pet\u00E9n" },
      {
        provinceCode: "QUE",
        id: 1978,
        label: "Region",
        province: "Quetzaltenango",
      },
      { provinceCode: "QUI", id: 1994, label: "Region", province: "Quich\u00E9" },
      { provinceCode: "RET", id: 1993, label: "Region", province: "Retalhuleu" },
      {
        provinceCode: "SAC",
        id: 1985,
        label: "Region",
        province: "Sacatep\u00E9quez",
      },
      { provinceCode: "SMA", id: 1996, label: "Region", province: "San Marcos" },
      { provinceCode: "SRO", id: 1977, label: "Region", province: "Santa Rosa" },
      { provinceCode: "SOL", id: 1995, label: "Region", province: "Solol\u00E1" },
      {
        provinceCode: "SUC",
        id: 1984,
        label: "Region",
        province: "Suchitep\u00E9quez",
      },
      {
        provinceCode: "TOT",
        id: 1979,
        label: "Region",
        province: "Totonicap\u00E1n",
      },
      { provinceCode: "ZAC", id: 1982, label: "Region", province: "Zacapa" },
    ],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "GG" as CountryCode,
    id: 88,
    provinceLabel: "Region",
    country: "Guernsey",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "GN" as CountryCode,
    id: 89,
    provinceLabel: "Region",
    country: "Guinea",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "GW" as CountryCode,
    id: 90,
    provinceLabel: "Region",
    country: "Guinea Bissau",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "GY" as CountryCode,
    id: 91,
    provinceLabel: "Region",
    country: "Guyana",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "HT" as CountryCode,
    id: 92,
    provinceLabel: "Region",
    country: "Haiti",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "HM" as CountryCode,
    id: 93,
    provinceLabel: "Region",
    country: "Heard Island And Mcdonald Islands",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "VA" as CountryCode,
    id: 94,
    provinceLabel: "Region",
    country: "Holy See (Vatican City State)",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "HN" as CountryCode,
    id: 95,
    provinceLabel: "Region",
    country: "Honduras",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "HK" as CountryCode,
    id: 96,
    provinceLabel: "Region",
    country: "Hong Kong",
    provinces: [
      {
        provinceCode: "HK",
        id: 2585,
        label: "Region",
        province: "Hong Kong Island",
      },
      { provinceCode: "KL", id: 2905, label: "Region", province: "Kowloon" },
      { provinceCode: "NT", id: 2906, label: "Region", province: "New Territories" },
    ],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "HU" as CountryCode,
    id: 97,
    provinceLabel: "Region",
    country: "Hungary",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "IS" as CountryCode,
    id: 98,
    provinceLabel: "Region",
    country: "Iceland",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "IN" as CountryCode,
    id: 99,
    provinceLabel: "State",
    country: "India",
    provinces: [
      {
        provinceCode: "AN",
        id: 2027,
        label: "State",
        province: "Andaman and Nicobar",
      },
      { provinceCode: "AP", id: 2045, label: "State", province: "Andhra Pradesh" },
      {
        provinceCode: "AR",
        id: 2049,
        label: "State",
        province: "Arunachal Pradesh",
      },
      { provinceCode: "AS", id: 2037, label: "State", province: "Assam" },
      { provinceCode: "BR", id: 2028, label: "State", province: "Bihar" },
      { provinceCode: "CH", id: 2030, label: "State", province: "Chandigarh" },
      { provinceCode: "CG", id: 2056, label: "State", province: "Chattisgarh" },
      {
        provinceCode: "DN",
        id: 2032,
        label: "State",
        province: "Dadra and Nagar Haveli",
      },
      { provinceCode: "DD", id: 2026, label: "State", province: "Daman and Diu" },
      { provinceCode: "DL", id: 2035, label: "State", province: "Delhi" },
      { provinceCode: "GA", id: 2024, label: "State", province: "Goa" },
      { provinceCode: "GJ", id: 2048, label: "State", province: "Gujarat" },
      { provinceCode: "HR", id: 2022, label: "State", province: "Haryana" },
      {
        provinceCode: "HP",
        id: 2046,
        label: "State",
        province: "Himachal Pradesh",
      },
      {
        provinceCode: "JK",
        id: 2031,
        label: "State",
        province: "Jammu and Kashmir",
      },
      { provinceCode: "JH", id: 2033, label: "State", province: "Jharkhand" },
      { provinceCode: "KA", id: 2054, label: "State", province: "Karnataka" },
      { provinceCode: "KL", id: 2025, label: "State", province: "Kerala" },
      { provinceCode: "LD", id: 2039, label: "State", province: "Lakshadweep" },
      { provinceCode: "MP", id: 2038, label: "State", province: "Madhya Pradesh" },
      { provinceCode: "MH", id: 2050, label: "State", province: "Maharashtra" },
      { provinceCode: "MN", id: 2040, label: "State", province: "Manipur" },
      { provinceCode: "ML", id: 2034, label: "State", province: "Meghalaya" },
      { provinceCode: "MZ", id: 2055, label: "State", province: "Mizoram" },
      { provinceCode: "NL", id: 2047, label: "State", province: "Nagaland" },
      { provinceCode: "OR", id: 2036, label: "State", province: "Orissa" },
      { provinceCode: "PY", id: 2053, label: "State", province: "Puducherry" },
      { provinceCode: "PB", id: 2023, label: "State", province: "Punjab" },
      { provinceCode: "RJ", id: 2041, label: "State", province: "Rajasthan" },
      { provinceCode: "SK", id: 2042, label: "State", province: "Sikkim" },
      { provinceCode: "TN", id: 2029, label: "State", province: "Tamil Nadu" },
      { provinceCode: "TS", id: 2052, label: "State", province: "Telangana" },
      { provinceCode: "TR", id: 2051, label: "State", province: "Tripura" },
      { provinceCode: "UP", id: 2057, label: "State", province: "Uttar Pradesh" },
      { provinceCode: "UK", id: 2044, label: "State", province: "Uttarakhand" },
      { provinceCode: "WB", id: 2043, label: "State", province: "West Bengal" },
    ],
    zipLabel: "Pincode",
    zipPlaceholder: "Pincode",
    zipRequired: true,
  },
  {
    countryCode: "ID" as CountryCode,
    id: 100,
    provinceLabel: "Province",
    country: "Indonesia",
    provinces: [
      { provinceCode: "AC", id: 2087, label: "Province", province: "Aceh" },
      { provinceCode: "BA", id: 2069, label: "Province", province: "Bali" },
      {
        provinceCode: "BB",
        id: 2071,
        label: "Province",
        province: "Bangka Belitung",
      },
      { provinceCode: "BT", id: 2074, label: "Province", province: "Banten" },
      { provinceCode: "BE", id: 2078, label: "Province", province: "Bengkulu" },
      { provinceCode: "GO", id: 2058, label: "Province", province: "Gorontalo" },
      { provinceCode: "JK", id: 2079, label: "Province", province: "Jakarta" },
      { provinceCode: "JA", id: 2066, label: "Province", province: "Jambi" },
      { provinceCode: "JB", id: 2073, label: "Province", province: "Jawa Barat" },
      { provinceCode: "JT", id: 2084, label: "Province", province: "Jawa Tengah" },
      { provinceCode: "JI", id: 2062, label: "Province", province: "Jawa Timur" },
      {
        provinceCode: "KB",
        id: 2067,
        label: "Province",
        province: "Kalimantan Barat",
      },
      {
        provinceCode: "KS",
        id: 2060,
        label: "Province",
        province: "Kalimantan Selatan",
      },
      {
        provinceCode: "KT",
        id: 2065,
        label: "Province",
        province: "Kalimantan Tengah",
      },
      {
        provinceCode: "KI",
        id: 2063,
        label: "Province",
        province: "Kalimantan Timur",
      },
      {
        provinceCode: "KU",
        id: 2064,
        label: "Province",
        province: "Kalimantan Utara",
      },
      {
        provinceCode: "KR",
        id: 2075,
        label: "Province",
        province: "Kepulauan Riau",
      },
      { provinceCode: "LA", id: 2059, label: "Province", province: "Lampung" },
      { provinceCode: "MA", id: 2068, label: "Province", province: "Maluku" },
      { provinceCode: "MU", id: 2077, label: "Province", province: "Maluku Utara" },
      {
        provinceCode: "NB",
        id: 2072,
        label: "Province",
        province: "Nusa Tenggara Barat",
      },
      {
        provinceCode: "NT",
        id: 2081,
        label: "Province",
        province: "Nusa Tenggara Timur",
      },
      { provinceCode: "PA", id: 2080, label: "Province", province: "Papua" },
      { provinceCode: "PB", id: 2089, label: "Province", province: "Papua Barat" },
      { provinceCode: "RI", id: 2061, label: "Province", province: "Riau" },
      {
        provinceCode: "SR",
        id: 2091,
        label: "Province",
        province: "Sulawesi Barat",
      },
      {
        provinceCode: "SN",
        id: 2083,
        label: "Province",
        province: "Sulawesi Selatan",
      },
      {
        provinceCode: "ST",
        id: 2070,
        label: "Province",
        province: "Sulawesi Tengah",
      },
      {
        provinceCode: "SG",
        id: 2085,
        label: "Province",
        province: "Sulawesi Tenggara",
      },
      {
        provinceCode: "SA",
        id: 2090,
        label: "Province",
        province: "Sulawesi Utara",
      },
      {
        provinceCode: "SB",
        id: 2086,
        label: "Province",
        province: "Sumatra Barat",
      },
      {
        provinceCode: "SS",
        id: 2088,
        label: "Province",
        province: "Sumatra Selatan",
      },
      {
        provinceCode: "SU",
        id: 2076,
        label: "Province",
        province: "Sumatra Utara",
      },
      { provinceCode: "YO", id: 2082, label: "Province", province: "Yogyakarta" },
    ],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "IR" as CountryCode,
    id: 101,
    provinceLabel: "Region",
    country: "Iran, Islamic Republic Of",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "IQ" as CountryCode,
    id: 102,
    provinceLabel: "Region",
    country: "Iraq",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "IE" as CountryCode,
    id: 103,
    provinceLabel: "County",
    country: "Ireland",
    provinces: [
      { provinceCode: "CW", id: 2660, label: "County", province: "Carlow" },
      { provinceCode: "CN", id: 2644, label: "County", province: "Cavan" },
      { provinceCode: "CE", id: 2663, label: "County", province: "Clare" },
      { provinceCode: "CO", id: 2651, label: "County", province: "Cork" },
      { provinceCode: "DL", id: 2662, label: "County", province: "Donegal" },
      { provinceCode: "D", id: 2657, label: "County", province: "Dublin" },
      { provinceCode: "G", id: 2655, label: "County", province: "Galway" },
      { provinceCode: "KY", id: 2648, label: "County", province: "Kerry" },
      { provinceCode: "KE", id: 2647, label: "County", province: "Kildare" },
      { provinceCode: "KK", id: 2656, label: "County", province: "Kilkenny" },
      { provinceCode: "LS", id: 2650, label: "County", province: "Laois" },
      { provinceCode: "LM", id: 2661, label: "County", province: "Leitrim" },
      { provinceCode: "LK", id: 2664, label: "County", province: "Limerick" },
      { provinceCode: "LD", id: 2659, label: "County", province: "Longford" },
      { provinceCode: "LH", id: 2654, label: "County", province: "Louth" },
      { provinceCode: "MO", id: 2649, label: "County", province: "Mayo" },
      { provinceCode: "MH", id: 2666, label: "County", province: "Meath" },
      { provinceCode: "MN", id: 2653, label: "County", province: "Monaghan" },
      { provinceCode: "OY", id: 2667, label: "County", province: "Offaly" },
      { provinceCode: "RN", id: 2652, label: "County", province: "Roscommon" },
      { provinceCode: "SO", id: 2643, label: "County", province: "Sligo" },
      { provinceCode: "TA", id: 2646, label: "County", province: "Tipperary" },
      { provinceCode: "WD", id: 2645, label: "County", province: "Waterford" },
      { provinceCode: "WH", id: 2665, label: "County", province: "Westmeath" },
      { provinceCode: "WX", id: 2668, label: "County", province: "Wexford" },
      { provinceCode: "WW", id: 2658, label: "County", province: "Wicklow" },
    ],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "IM" as CountryCode,
    id: 104,
    provinceLabel: "Region",
    country: "Isle Of Man",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "IL" as CountryCode,
    id: 105,
    provinceLabel: "Region",
    country: "Israel",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "IT" as CountryCode,
    id: 106,
    provinceLabel: "Province",
    country: "Italy",
    provinces: [
      { provinceCode: "AG", id: 2397, label: "Province", province: "Agrigento" },
      { provinceCode: "AL", id: 2372, label: "Province", province: "Alessandria" },
      { provinceCode: "AN", id: 2373, label: "Province", province: "Ancona" },
      { provinceCode: "AO", id: 2405, label: "Province", province: "Aosta" },
      { provinceCode: "AR", id: 2476, label: "Province", province: "Arezzo" },
      {
        provinceCode: "AP",
        id: 2453,
        label: "Province",
        province: "Ascoli Piceno",
      },
      { provinceCode: "AT", id: 2456, label: "Province", province: "Asti" },
      { provinceCode: "AV", id: 2378, label: "Province", province: "Avellino" },
      { provinceCode: "BA", id: 2425, label: "Province", province: "Bari" },
      {
        provinceCode: "BT",
        id: 2436,
        label: "Province",
        province: "Barletta-Andria-Trani",
      },
      { provinceCode: "BL", id: 2416, label: "Province", province: "Belluno" },
      { provinceCode: "BN", id: 2426, label: "Province", province: "Benevento" },
      { provinceCode: "BG", id: 2388, label: "Province", province: "Bergamo" },
      { provinceCode: "BI", id: 2460, label: "Province", province: "Biella" },
      { provinceCode: "BO", id: 2368, label: "Province", province: "Bologna" },
      { provinceCode: "BZ", id: 2389, label: "Province", province: "Bolzano" },
      { provinceCode: "BS", id: 2395, label: "Province", province: "Brescia" },
      { provinceCode: "BR", id: 2448, label: "Province", province: "Brindisi" },
      { provinceCode: "CA", id: 2438, label: "Province", province: "Cagliari" },
      {
        provinceCode: "CL",
        id: 2410,
        label: "Province",
        province: "Caltanissetta",
      },
      { provinceCode: "CB", id: 2391, label: "Province", province: "Campobasso" },
      {
        provinceCode: "CI",
        id: 2454,
        label: "Province",
        province: "Carbonia-Iglesias",
      },
      { provinceCode: "CE", id: 2371, label: "Province", province: "Caserta" },
      { provinceCode: "CT", id: 2383, label: "Province", province: "Catania" },
      { provinceCode: "CZ", id: 2415, label: "Province", province: "Catanzaro" },
      { provinceCode: "CH", id: 2421, label: "Province", province: "Chieti" },
      { provinceCode: "CO", id: 2435, label: "Province", province: "Como" },
      { provinceCode: "CS", id: 2464, label: "Province", province: "Cosenza" },
      { provinceCode: "CR", id: 2468, label: "Province", province: "Cremona" },
      { provinceCode: "KR", id: 2422, label: "Province", province: "Crotone" },
      { provinceCode: "CN", id: 2463, label: "Province", province: "Cuneo" },
      { provinceCode: "EN", id: 2401, label: "Province", province: "Enna" },
      { provinceCode: "FM", id: 2392, label: "Province", province: "Fermo" },
      { provinceCode: "FE", id: 2420, label: "Province", province: "Ferrara" },
      { provinceCode: "FI", id: 2406, label: "Province", province: "Firenze" },
      { provinceCode: "FG", id: 2423, label: "Province", province: "Foggia" },
      {
        provinceCode: "FC",
        id: 2380,
        label: "Province",
        province: "Forl\u00EC-Cesena",
      },
      { provinceCode: "FR", id: 2413, label: "Province", province: "Frosinone" },
      { provinceCode: "GE", id: 2400, label: "Province", province: "Genova" },
      { provinceCode: "GO", id: 2419, label: "Province", province: "Gorizia" },
      { provinceCode: "GR", id: 2369, label: "Province", province: "Grosseto" },
      { provinceCode: "IM", id: 2439, label: "Province", province: "Imperia" },
      { provinceCode: "IS", id: 2469, label: "Province", province: "Isernia" },
      { provinceCode: "AQ", id: 2443, label: "Province", province: "L'Aquila" },
      { provinceCode: "SP", id: 2412, label: "Province", province: "La Spezia" },
      { provinceCode: "LT", id: 2429, label: "Province", province: "Latina" },
      { provinceCode: "LE", id: 2433, label: "Province", province: "Lecce" },
      { provinceCode: "LC", id: 2432, label: "Province", province: "Lecco" },
      { provinceCode: "LI", id: 2386, label: "Province", province: "Livorno" },
      { provinceCode: "LO", id: 2376, label: "Province", province: "Lodi" },
      { provinceCode: "LU", id: 2394, label: "Province", province: "Lucca" },
      { provinceCode: "MC", id: 2449, label: "Province", province: "Macerata" },
      { provinceCode: "MN", id: 2458, label: "Province", province: "Mantova" },
      {
        provinceCode: "MS",
        id: 2427,
        label: "Province",
        province: "Massa-Carrara",
      },
      { provinceCode: "MT", id: 2441, label: "Province", province: "Matera" },
      {
        provinceCode: "VS",
        id: 2403,
        label: "Province",
        province: "Medio Campidano",
      },
      { provinceCode: "ME", id: 2431, label: "Province", province: "Messina" },
      { provinceCode: "MI", id: 2444, label: "Province", province: "Milano" },
      { provinceCode: "MO", id: 2408, label: "Province", province: "Modena" },
      {
        provinceCode: "MB",
        id: 2465,
        label: "Province",
        province: "Monza e Brianza",
      },
      { provinceCode: "NA", id: 2390, label: "Province", province: "Napoli" },
      { provinceCode: "NO", id: 2377, label: "Province", province: "Novara" },
      { provinceCode: "NU", id: 2402, label: "Province", province: "Nuoro" },
      { provinceCode: "OG", id: 2385, label: "Province", province: "Ogliastra" },
      { provinceCode: "OT", id: 2387, label: "Province", province: "Olbia-Tempio" },
      { provinceCode: "OR", id: 2455, label: "Province", province: "Oristano" },
      { provinceCode: "PD", id: 2467, label: "Province", province: "Padova" },
      { provinceCode: "PA", id: 2440, label: "Province", province: "Palermo" },
      { provinceCode: "PR", id: 2472, label: "Province", province: "Parma" },
      { provinceCode: "PV", id: 2374, label: "Province", province: "Pavia" },
      { provinceCode: "PG", id: 2424, label: "Province", province: "Perugia" },
      {
        provinceCode: "PU",
        id: 2471,
        label: "Province",
        province: "Pesaro e Urbino",
      },
      { provinceCode: "PE", id: 2398, label: "Province", province: "Pescara" },
      { provinceCode: "PC", id: 2396, label: "Province", province: "Piacenza" },
      { provinceCode: "PI", id: 2428, label: "Province", province: "Pisa" },
      { provinceCode: "PT", id: 2434, label: "Province", province: "Pistoia" },
      { provinceCode: "PN", id: 2417, label: "Province", province: "Pordenone" },
      { provinceCode: "PZ", id: 2447, label: "Province", province: "Potenza" },
      { provinceCode: "PO", id: 2462, label: "Province", province: "Prato" },
      { provinceCode: "RG", id: 2437, label: "Province", province: "Ragusa" },
      { provinceCode: "RA", id: 2457, label: "Province", province: "Ravenna" },
      {
        provinceCode: "RC",
        id: 2452,
        label: "Province",
        province: "Reggio Calabria",
      },
      {
        provinceCode: "RE",
        id: 2411,
        label: "Province",
        province: "Reggio Emilia",
      },
      { provinceCode: "RI", id: 2445, label: "Province", province: "Rieti" },
      { provinceCode: "RN", id: 2461, label: "Province", province: "Rimini" },
      { provinceCode: "RM", id: 2393, label: "Province", province: "Roma" },
      { provinceCode: "RO", id: 2382, label: "Province", province: "Rovigo" },
      { provinceCode: "SA", id: 2430, label: "Province", province: "Salerno" },
      { provinceCode: "SS", id: 2475, label: "Province", province: "Sassari" },
      { provinceCode: "SV", id: 2459, label: "Province", province: "Savona" },
      { provinceCode: "SI", id: 2450, label: "Province", province: "Siena" },
      { provinceCode: "SR", id: 2367, label: "Province", province: "Siracusa" },
      { provinceCode: "SO", id: 2466, label: "Province", province: "Sondrio" },
      { provinceCode: "TA", id: 2414, label: "Province", province: "Taranto" },
      { provinceCode: "TE", id: 2375, label: "Province", province: "Teramo" },
      { provinceCode: "TR", id: 2446, label: "Province", province: "Terni" },
      { provinceCode: "TO", id: 2370, label: "Province", province: "Torino" },
      { provinceCode: "TP", id: 2407, label: "Province", province: "Trapani" },
      { provinceCode: "TN", id: 2470, label: "Province", province: "Trento" },
      { provinceCode: "TV", id: 2384, label: "Province", province: "Treviso" },
      { provinceCode: "TS", id: 2404, label: "Province", province: "Trieste" },
      { provinceCode: "UD", id: 2409, label: "Province", province: "Udine" },
      { provinceCode: "VA", id: 2473, label: "Province", province: "Varese" },
      { provinceCode: "VE", id: 2474, label: "Province", province: "Venezia" },
      {
        provinceCode: "VB",
        id: 2381,
        label: "Province",
        province: "Verbano-Cusio-Ossola",
      },
      { provinceCode: "VC", id: 2399, label: "Province", province: "Vercelli" },
      { provinceCode: "VR", id: 2379, label: "Province", province: "Verona" },
      {
        provinceCode: "VV",
        id: 2451,
        label: "Province",
        province: "Vibo Valentia",
      },
      { provinceCode: "VI", id: 2442, label: "Province", province: "Vicenza" },
      { provinceCode: "VT", id: 2418, label: "Province", province: "Viterbo" },
    ],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "JM" as CountryCode,
    id: 107,
    provinceLabel: "Region",
    country: "Jamaica",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "JP" as CountryCode,
    id: 108,
    provinceLabel: "Prefecture",
    country: "Japan",
    provinces: [
      { provinceCode: "JP-23", id: 2487, label: "Prefecture", province: "Aichi" },
      { provinceCode: "JP-05", id: 2518, label: "Prefecture", province: "Akita" },
      { provinceCode: "JP-02", id: 2502, label: "Prefecture", province: "Aomori" },
      { provinceCode: "JP-12", id: 2486, label: "Prefecture", province: "Chiba" },
      { provinceCode: "JP-38", id: 2482, label: "Prefecture", province: "Ehime" },
      { provinceCode: "JP-18", id: 2509, label: "Prefecture", province: "Fukui" },
      { provinceCode: "JP-40", id: 2500, label: "Prefecture", province: "Fukuoka" },
      {
        provinceCode: "JP-07",
        id: 2520,
        label: "Prefecture",
        province: "Fukushima",
      },
      { provinceCode: "JP-21", id: 2503, label: "Prefecture", province: "Gifu" },
      { provinceCode: "JP-10", id: 2494, label: "Prefecture", province: "Gunma" },
      {
        provinceCode: "JP-34",
        id: 2507,
        label: "Prefecture",
        province: "Hiroshima",
      },
      {
        provinceCode: "JP-01",
        id: 2491,
        label: "Prefecture",
        province: "Hokkaid\u014D",
      },
      {
        provinceCode: "JP-28",
        id: 2516,
        label: "Prefecture",
        province: "Hy\u014Dgo",
      },
      { provinceCode: "JP-08", id: 2510, label: "Prefecture", province: "Ibaraki" },
      {
        provinceCode: "JP-17",
        id: 2498,
        label: "Prefecture",
        province: "Ishikawa",
      },
      { provinceCode: "JP-03", id: 2496, label: "Prefecture", province: "Iwate" },
      { provinceCode: "JP-37", id: 2480, label: "Prefecture", province: "Kagawa" },
      {
        provinceCode: "JP-46",
        id: 2521,
        label: "Prefecture",
        province: "Kagoshima",
      },
      {
        provinceCode: "JP-14",
        id: 2479,
        label: "Prefecture",
        province: "Kanagawa",
      },
      {
        provinceCode: "JP-43",
        id: 2484,
        label: "Prefecture",
        province: "Kumamoto",
      },
      {
        provinceCode: "JP-26",
        id: 2519,
        label: "Prefecture",
        province: "Ky\u014Dto",
      },
      {
        provinceCode: "JP-39",
        id: 2489,
        label: "Prefecture",
        province: "K\u014Dchi",
      },
      { provinceCode: "JP-24", id: 2483, label: "Prefecture", province: "Mie" },
      { provinceCode: "JP-04", id: 2493, label: "Prefecture", province: "Miyagi" },
      {
        provinceCode: "JP-45",
        id: 2501,
        label: "Prefecture",
        province: "Miyazaki",
      },
      { provinceCode: "JP-20", id: 2505, label: "Prefecture", province: "Nagano" },
      {
        provinceCode: "JP-42",
        id: 2488,
        label: "Prefecture",
        province: "Nagasaki",
      },
      { provinceCode: "JP-29", id: 2490, label: "Prefecture", province: "Nara" },
      { provinceCode: "JP-15", id: 2508, label: "Prefecture", province: "Niigata" },
      { provinceCode: "JP-33", id: 2517, label: "Prefecture", province: "Okayama" },
      { provinceCode: "JP-47", id: 2495, label: "Prefecture", province: "Okinawa" },
      { provinceCode: "JP-41", id: 2513, label: "Prefecture", province: "Saga" },
      { provinceCode: "JP-11", id: 2512, label: "Prefecture", province: "Saitama" },
      { provinceCode: "JP-25", id: 2492, label: "Prefecture", province: "Shiga" },
      { provinceCode: "JP-32", id: 2477, label: "Prefecture", province: "Shimane" },
      {
        provinceCode: "JP-22",
        id: 2481,
        label: "Prefecture",
        province: "Shizuoka",
      },
      { provinceCode: "JP-09", id: 2504, label: "Prefecture", province: "Tochigi" },
      {
        provinceCode: "JP-36",
        id: 2522,
        label: "Prefecture",
        province: "Tokushima",
      },
      { provinceCode: "JP-31", id: 2523, label: "Prefecture", province: "Tottori" },
      { provinceCode: "JP-16", id: 2506, label: "Prefecture", province: "Toyama" },
      {
        provinceCode: "JP-13",
        id: 2497,
        label: "Prefecture",
        province: "T\u014Dky\u014D",
      },
      {
        provinceCode: "JP-30",
        id: 2514,
        label: "Prefecture",
        province: "Wakayama",
      },
      {
        provinceCode: "JP-06",
        id: 2499,
        label: "Prefecture",
        province: "Yamagata",
      },
      {
        provinceCode: "JP-35",
        id: 2511,
        label: "Prefecture",
        province: "Yamaguchi",
      },
      {
        provinceCode: "JP-19",
        id: 2478,
        label: "Prefecture",
        province: "Yamanashi",
      },
      {
        provinceCode: "JP-44",
        id: 2485,
        label: "Prefecture",
        province: "\u014Cita",
      },
      {
        provinceCode: "JP-27",
        id: 2515,
        label: "Prefecture",
        province: "\u014Csaka",
      },
    ],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "JE" as CountryCode,
    id: 109,
    provinceLabel: "Region",
    country: "Jersey",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "JO" as CountryCode,
    id: 110,
    provinceLabel: "Region",
    country: "Jordan",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "KZ" as CountryCode,
    id: 111,
    provinceLabel: "Region",
    country: "Kazakhstan",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "KE" as CountryCode,
    id: 112,
    provinceLabel: "Region",
    country: "Kenya",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "KI" as CountryCode,
    id: 113,
    provinceLabel: "Region",
    country: "Kiribati",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "KP" as CountryCode,
    id: 114,
    provinceLabel: "Region",
    country: "Korea, Democratic People's Republic Of",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "XK" as CountryCode,
    id: 115,
    provinceLabel: "Region",
    country: "Kosovo",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "KW" as CountryCode,
    id: 116,
    provinceLabel: "Region",
    country: "Kuwait",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "KG" as CountryCode,
    id: 117,
    provinceLabel: "Region",
    country: "Kyrgyzstan",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "LA" as CountryCode,
    id: 118,
    provinceLabel: "Province",
    country: "Lao People's Democratic Republic",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "LV" as CountryCode,
    id: 119,
    provinceLabel: "Region",
    country: "Latvia",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "LB" as CountryCode,
    id: 120,
    provinceLabel: "Region",
    country: "Lebanon",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "LS" as CountryCode,
    id: 121,
    provinceLabel: "Region",
    country: "Lesotho",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "LR" as CountryCode,
    id: 122,
    provinceLabel: "Region",
    country: "Liberia",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "LY" as CountryCode,
    id: 123,
    provinceLabel: "Region",
    country: "Libyan Arab Jamahiriya",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "LI" as CountryCode,
    id: 124,
    provinceLabel: "Region",
    country: "Liechtenstein",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "LT" as CountryCode,
    id: 125,
    provinceLabel: "Region",
    country: "Lithuania",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "LU" as CountryCode,
    id: 126,
    provinceLabel: "Region",
    country: "Luxembourg",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "MO" as CountryCode,
    id: 127,
    provinceLabel: "Region",
    country: "Macao",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "MG" as CountryCode,
    id: 129,
    provinceLabel: "Region",
    country: "Madagascar",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "MW" as CountryCode,
    id: 130,
    provinceLabel: "Region",
    country: "Malawi",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "MY" as CountryCode,
    id: 131,
    provinceLabel: "State/territory",
    country: "Malaysia",
    provinces: [
      {
        provinceCode: "JHR",
        id: 2253,
        label: "State/territory",
        province: "Johor",
      },
      {
        provinceCode: "KDH",
        id: 2247,
        label: "State/territory",
        province: "Kedah",
      },
      {
        provinceCode: "KTN",
        id: 2252,
        label: "State/territory",
        province: "Kelantan",
      },
      {
        provinceCode: "KUL",
        id: 2257,
        label: "State/territory",
        province: "Kuala Lumpur",
      },
      {
        provinceCode: "LBN",
        id: 2251,
        label: "State/territory",
        province: "Labuan",
      },
      {
        provinceCode: "MLK",
        id: 2246,
        label: "State/territory",
        province: "Melaka",
      },
      {
        provinceCode: "NSN",
        id: 2250,
        label: "State/territory",
        province: "Negeri Sembilan",
      },
      {
        provinceCode: "PHG",
        id: 2245,
        label: "State/territory",
        province: "Pahang",
      },
      {
        provinceCode: "PRK",
        id: 2258,
        label: "State/territory",
        province: "Perak",
      },
      {
        provinceCode: "PLS",
        id: 2259,
        label: "State/territory",
        province: "Perlis",
      },
      {
        provinceCode: "PNG",
        id: 2255,
        label: "State/territory",
        province: "Pulau Pinang",
      },
      {
        provinceCode: "PJY",
        id: 2249,
        label: "State/territory",
        province: "Putrajaya",
      },
      {
        provinceCode: "SBH",
        id: 2254,
        label: "State/territory",
        province: "Sabah",
      },
      {
        provinceCode: "SWK",
        id: 2244,
        label: "State/territory",
        province: "Sarawak",
      },
      {
        provinceCode: "SGR",
        id: 2248,
        label: "State/territory",
        province: "Selangor",
      },
      {
        provinceCode: "TRG",
        id: 2256,
        label: "State/territory",
        province: "Terengganu",
      },
    ],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "MV" as CountryCode,
    id: 132,
    provinceLabel: "Region",
    country: "Maldives",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "ML" as CountryCode,
    id: 133,
    provinceLabel: "Region",
    country: "Mali",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "MT" as CountryCode,
    id: 134,
    provinceLabel: "Region",
    country: "Malta",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "MQ" as CountryCode,
    id: 135,
    provinceLabel: "Region",
    country: "Martinique",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "MR" as CountryCode,
    id: 136,
    provinceLabel: "Region",
    country: "Mauritania",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "MU" as CountryCode,
    id: 137,
    provinceLabel: "Region",
    country: "Mauritius",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "YT" as CountryCode,
    id: 138,
    provinceLabel: "Region",
    country: "Mayotte",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "MX" as CountryCode,
    id: 139,
    provinceLabel: "State",
    country: "Mexico",
    provinces: [
      { provinceCode: "AGS", id: 2530, label: "State", province: "Aguascalientes" },
      { provinceCode: "BC", id: 2535, label: "State", province: "Baja California" },
      {
        provinceCode: "BCS",
        id: 2540,
        label: "State",
        province: "Baja California Sur",
      },
      { provinceCode: "CAMP", id: 2549, label: "State", province: "Campeche" },
      { provinceCode: "CHIS", id: 2533, label: "State", province: "Chiapas" },
      { provinceCode: "CHIH", id: 2524, label: "State", province: "Chihuahua" },
      {
        provinceCode: "DF",
        id: 2547,
        label: "State",
        province: "Ciudad de M\u00E9xico",
      },
      { provinceCode: "COAH", id: 2555, label: "State", province: "Coahuila" },
      { provinceCode: "COL", id: 2537, label: "State", province: "Colima" },
      { provinceCode: "DGO", id: 2550, label: "State", province: "Durango" },
      { provinceCode: "GTO", id: 2526, label: "State", province: "Guanajuato" },
      { provinceCode: "GRO", id: 2529, label: "State", province: "Guerrero" },
      { provinceCode: "HGO", id: 2551, label: "State", province: "Hidalgo" },
      { provinceCode: "JAL", id: 2545, label: "State", province: "Jalisco" },
      {
        provinceCode: "MICH",
        id: 2548,
        label: "State",
        province: "Michoac\u00E1n",
      },
      { provinceCode: "MOR", id: 2553, label: "State", province: "Morelos" },
      { provinceCode: "MEX", id: 2554, label: "State", province: "M\u00E9xico" },
      { provinceCode: "NAY", id: 2532, label: "State", province: "Nayarit" },
      { provinceCode: "NL", id: 2546, label: "State", province: "Nuevo Le\u00F3n" },
      { provinceCode: "OAx", id: 2544, label: "State", province: "Oaxaca" },
      { provinceCode: "PUE", id: 2542, label: "State", province: "Puebla" },
      { provinceCode: "QRO", id: 2541, label: "State", province: "Quer\u00E9taro" },
      { provinceCode: "Q ROO", id: 2536, label: "State", province: "Quintana Roo" },
      {
        provinceCode: "SLP",
        id: 2543,
        label: "State",
        province: "San Luis Potos\u00ED",
      },
      { provinceCode: "SIN", id: 2527, label: "State", province: "Sinaloa" },
      { provinceCode: "SON", id: 2538, label: "State", province: "Sonora" },
      { provinceCode: "TAB", id: 2539, label: "State", province: "Tabasco" },
      { provinceCode: "TAMPS", id: 2528, label: "State", province: "Tamaulipas" },
      { provinceCode: "TLAX", id: 2531, label: "State", province: "Tlaxcala" },
      { provinceCode: "VER", id: 2552, label: "State", province: "Veracruz" },
      { provinceCode: "YUC", id: 2525, label: "State", province: "Yucat\u00E1n" },
      { provinceCode: "ZAC", id: 2534, label: "State", province: "Zacatecas" },
    ],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "MD" as CountryCode,
    id: 140,
    provinceLabel: "Region",
    country: "Moldova, Republic of",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "MC" as CountryCode,
    id: 141,
    provinceLabel: "Region",
    country: "Monaco",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "MN" as CountryCode,
    id: 142,
    provinceLabel: "Province",
    country: "Mongolia",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "ME" as CountryCode,
    id: 143,
    provinceLabel: "Region",
    country: "Montenegro",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "MS" as CountryCode,
    id: 144,
    provinceLabel: "Region",
    country: "Montserrat",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "MA" as CountryCode,
    id: 145,
    provinceLabel: "Region",
    country: "Morocco",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "MZ" as CountryCode,
    id: 146,
    provinceLabel: "Region",
    country: "Mozambique",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "MM" as CountryCode,
    id: 34,
    provinceLabel: "Region",
    country: "Myanmar",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "NA" as CountryCode,
    id: 147,
    provinceLabel: "Region",
    country: "Namibia",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "NR" as CountryCode,
    id: 148,
    provinceLabel: "Region",
    country: "Nauru",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "NP" as CountryCode,
    id: 149,
    provinceLabel: "Region",
    country: "Nepal",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "NL" as CountryCode,
    id: 150,
    provinceLabel: "Region",
    country: "Netherlands",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "AN" as CountryCode,
    id: 151,
    provinceLabel: "Region",
    country: "Netherlands Antilles",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "NC" as CountryCode,
    id: 152,
    provinceLabel: "Region",
    country: "New Caledonia",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "NZ" as CountryCode,
    id: 153,
    provinceLabel: "Region",
    country: "New Zealand",
    provinces: [
      { provinceCode: "AUK", id: 2001, label: "Region", province: "Auckland" },
      { provinceCode: "BOP", id: 2004, label: "Region", province: "Bay of Plenty" },
      { provinceCode: "CAN", id: 2010, label: "Region", province: "Canterbury" },
      { provinceCode: "GIS", id: 2009, label: "Region", province: "Gisborne" },
      { provinceCode: "HKB", id: 2013, label: "Region", province: "Hawke's Bay" },
      {
        provinceCode: "MWT",
        id: 2011,
        label: "Region",
        province: "Manawatu-Wanganui",
      },
      { provinceCode: "MBH", id: 2003, label: "Region", province: "Marlborough" },
      { provinceCode: "NSN", id: 2014, label: "Region", province: "Nelson" },
      { provinceCode: "NTL", id: 2002, label: "Region", province: "Northland" },
      { provinceCode: "OTA", id: 1999, label: "Region", province: "Otago" },
      { provinceCode: "STL", id: 2012, label: "Region", province: "Southland" },
      { provinceCode: "TKI", id: 2000, label: "Region", province: "Taranaki" },
      { provinceCode: "TAS", id: 2006, label: "Region", province: "Tasman" },
      { provinceCode: "WKO", id: 2008, label: "Region", province: "Waikato" },
      { provinceCode: "WGN", id: 2005, label: "Region", province: "Wellington" },
      { provinceCode: "WTC", id: 2007, label: "Region", province: "West Coast" },
    ],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "NI" as CountryCode,
    id: 154,
    provinceLabel: "Region",
    country: "Nicaragua",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "NE" as CountryCode,
    id: 155,
    provinceLabel: "Region",
    country: "Niger",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "NG" as CountryCode,
    id: 156,
    provinceLabel: "State",
    country: "Nigeria",
    provinces: [
      { provinceCode: "AB", id: 2904, label: "State", province: "Abia" },
      {
        provinceCode: "FC",
        id: 2890,
        label: "State",
        province: "Abuja Federal Capital Territory",
      },
      { provinceCode: "AD", id: 2879, label: "State", province: "Adamawa" },
      { provinceCode: "AK", id: 2875, label: "State", province: "Akwa Ibom" },
      { provinceCode: "AN", id: 2871, label: "State", province: "Anambra" },
      { provinceCode: "BA", id: 2898, label: "State", province: "Bauchi" },
      { provinceCode: "BY", id: 2892, label: "State", province: "Bayelsa" },
      { provinceCode: "BE", id: 2885, label: "State", province: "Benue" },
      { provinceCode: "BO", id: 2902, label: "State", province: "Borno" },
      { provinceCode: "CR", id: 2876, label: "State", province: "Cross River" },
      { provinceCode: "DE", id: 2883, label: "State", province: "Delta" },
      { provinceCode: "EB", id: 2894, label: "State", province: "Ebonyi" },
      { provinceCode: "ED", id: 2899, label: "State", province: "Edo" },
      { provinceCode: "EK", id: 2893, label: "State", province: "Ekiti" },
      { provinceCode: "EN", id: 2895, label: "State", province: "Enugu" },
      { provinceCode: "GO", id: 2878, label: "State", province: "Gombe" },
      { provinceCode: "IM", id: 2872, label: "State", province: "Imo" },
      { provinceCode: "JI", id: 2886, label: "State", province: "Jigawa" },
      { provinceCode: "KD", id: 2868, label: "State", province: "Kaduna" },
      { provinceCode: "KN", id: 2896, label: "State", province: "Kano" },
      { provinceCode: "KT", id: 2877, label: "State", province: "Katsina" },
      { provinceCode: "KE", id: 2889, label: "State", province: "Kebbi" },
      { provinceCode: "KO", id: 2870, label: "State", province: "Kogi" },
      { provinceCode: "KW", id: 2884, label: "State", province: "Kwara" },
      { provinceCode: "LA", id: 2901, label: "State", province: "Lagos" },
      { provinceCode: "NA", id: 2891, label: "State", province: "Nasarawa" },
      { provinceCode: "NI", id: 2903, label: "State", province: "Niger" },
      { provinceCode: "OG", id: 2881, label: "State", province: "Ogun" },
      { provinceCode: "ON", id: 2869, label: "State", province: "Ondo" },
      { provinceCode: "OS", id: 2897, label: "State", province: "Osun" },
      { provinceCode: "OY", id: 2873, label: "State", province: "Oyo" },
      { provinceCode: "PL", id: 2874, label: "State", province: "Plateau" },
      { provinceCode: "RI", id: 2900, label: "State", province: "Rivers" },
      { provinceCode: "SO", id: 2882, label: "State", province: "Sokoto" },
      { provinceCode: "TA", id: 2888, label: "State", province: "Taraba" },
      { provinceCode: "YO", id: 2887, label: "State", province: "Yobe" },
      { provinceCode: "ZA", id: 2880, label: "State", province: "Zamfara" },
    ],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "NU" as CountryCode,
    id: 157,
    provinceLabel: "Region",
    country: "Niue",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "NF" as CountryCode,
    id: 158,
    provinceLabel: "Region",
    country: "Norfolk Island",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "MK" as CountryCode,
    id: 128,
    provinceLabel: "Region",
    country: "North Macedonia",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "NO" as CountryCode,
    id: 159,
    provinceLabel: "Region",
    country: "Norway",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "OM" as CountryCode,
    id: 160,
    provinceLabel: "Region",
    country: "Oman",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "PK" as CountryCode,
    id: 161,
    provinceLabel: "Region",
    country: "Pakistan",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "PS" as CountryCode,
    id: 162,
    provinceLabel: "Region",
    country: "Palestinian Territory, Occupied",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "PA" as CountryCode,
    id: 163,
    provinceLabel: "Region",
    country: "Panama",
    provinces: [
      {
        provinceCode: "PA-1",
        id: 2862,
        label: "Region",
        province: "Bocas del Toro",
      },
      {
        provinceCode: "PA-4",
        id: 2854,
        label: "Region",
        province: "Chiriqu\u00ED",
      },
      { provinceCode: "PA-2", id: 2856, label: "Region", province: "Cocl\u00E9" },
      { provinceCode: "PA-3", id: 2861, label: "Region", province: "Col\u00F3n" },
      { provinceCode: "PA-5", id: 2858, label: "Region", province: "Dari\u00E9n" },
      { provinceCode: "PA-EM", id: 2865, label: "Region", province: "Ember\u00E1" },
      { provinceCode: "PA-6", id: 2859, label: "Region", province: "Herrera" },
      { provinceCode: "PA-KY", id: 2860, label: "Region", province: "Kuna Yala" },
      { provinceCode: "PA-7", id: 2857, label: "Region", province: "Los Santos" },
      {
        provinceCode: "PA-NB",
        id: 2855,
        label: "Region",
        province: "Ng\u00F6be-Bugl\u00E9",
      },
      { provinceCode: "PA-8", id: 2866, label: "Region", province: "Panam\u00E1" },
      {
        provinceCode: "PA-10",
        id: 2864,
        label: "Region",
        province: "Panam\u00E1 Oeste",
      },
      { provinceCode: "PA-9", id: 2863, label: "Region", province: "Veraguas" },
    ],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "PG" as CountryCode,
    id: 164,
    provinceLabel: "Region",
    country: "Papua New Guinea",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "PY" as CountryCode,
    id: 165,
    provinceLabel: "Department",
    country: "Paraguay",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "PE" as CountryCode,
    id: 166,
    provinceLabel: "Region",
    country: "Peru",
    provinces: [
      { provinceCode: "PE-AMA", id: 2750, label: "Region", province: "Amazonas" },
      {
        provinceCode: "PE-APU",
        id: 2748,
        label: "Region",
        province: "Apur\u00EDmac",
      },
      { provinceCode: "PE-ARE", id: 2743, label: "Region", province: "Arequipa" },
      { provinceCode: "PE-AYA", id: 2737, label: "Region", province: "Ayacucho" },
      { provinceCode: "PE-CAJ", id: 2754, label: "Region", province: "Cajamarca" },
      { provinceCode: "PE-CAL", id: 2752, label: "Region", province: "Callao" },
      { provinceCode: "PE-CUS", id: 2741, label: "Region", province: "Cuzco" },
      {
        provinceCode: "PE-HUV",
        id: 2757,
        label: "Region",
        province: "Huancavelica",
      },
      {
        provinceCode: "PE-HUC",
        id: 2742,
        label: "Region",
        province: "Hu\u00E1nuco",
      },
      { provinceCode: "PE-ICA", id: 2735, label: "Region", province: "Ica" },
      { provinceCode: "PE-JUN", id: 2736, label: "Region", province: "Jun\u00EDn" },
      {
        provinceCode: "PE-LAL",
        id: 2747,
        label: "Region",
        province: "La Libertad",
      },
      { provinceCode: "PE-LAM", id: 2746, label: "Region", province: "Lambayeque" },
      {
        provinceCode: "PE-LIM",
        id: 2749,
        label: "Region",
        province: "Lima (departamento)",
      },
      {
        provinceCode: "PE-LMA",
        id: 2755,
        label: "Region",
        province: "Lima (provincia)",
      },
      { provinceCode: "PE-LOR", id: 2734, label: "Region", province: "Loreto" },
      {
        provinceCode: "PE-MDD",
        id: 2744,
        label: "Region",
        province: "Madre de Dios",
      },
      { provinceCode: "PE-MOQ", id: 2753, label: "Region", province: "Moquegua" },
      { provinceCode: "PE-PAS", id: 2733, label: "Region", province: "Pasco" },
      { provinceCode: "PE-PIU", id: 2758, label: "Region", province: "Piura" },
      { provinceCode: "PE-PUN", id: 2745, label: "Region", province: "Puno" },
      {
        provinceCode: "PE-SAM",
        id: 2739,
        label: "Region",
        province: "San Mart\u00EDn",
      },
      { provinceCode: "PE-TAC", id: 2756, label: "Region", province: "Tacna" },
      { provinceCode: "PE-TUM", id: 2740, label: "Region", province: "Tumbes" },
      { provinceCode: "PE-UCA", id: 2751, label: "Region", province: "Ucayali" },
      { provinceCode: "PE-ANC", id: 2738, label: "Region", province: "\u00C1ncash" },
    ],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "PH" as CountryCode,
    id: 167,
    provinceLabel: "Region",
    country: "Philippines",
    provinces: [
      { provinceCode: "PH-ABR", id: 2907, label: "Region", province: "Abra" },
      {
        provinceCode: "PH-AGN",
        id: 2908,
        label: "Region",
        province: "Agusan del Norte",
      },
      {
        provinceCode: "PH-AGS",
        id: 2909,
        label: "Region",
        province: "Agusan del Sur",
      },
      { provinceCode: "PH-AKL", id: 2910, label: "Region", province: "Aklan" },
      { provinceCode: "PH-ALB", id: 2911, label: "Region", province: "Albay" },
      { provinceCode: "PH-ANT", id: 2912, label: "Region", province: "Antique" },
      { provinceCode: "PH-APA", id: 2913, label: "Region", province: "Apayao" },
      { provinceCode: "PH-AUR", id: 2914, label: "Region", province: "Aurora" },
      { provinceCode: "PH-BAS", id: 2915, label: "Region", province: "Basilan" },
      { provinceCode: "PH-BAN", id: 2916, label: "Region", province: "Bataan" },
      { provinceCode: "PH-BTN", id: 2917, label: "Region", province: "Batanes" },
      { provinceCode: "PH-BTG", id: 2918, label: "Region", province: "Batangas" },
      { provinceCode: "PH-BEN", id: 2919, label: "Region", province: "Benguet" },
      { provinceCode: "PH-BIL", id: 2920, label: "Region", province: "Biliran" },
      { provinceCode: "PH-BOH", id: 2921, label: "Region", province: "Bohol" },
      { provinceCode: "PH-BUK", id: 2922, label: "Region", province: "Bukidnon" },
      { provinceCode: "PH-BUL", id: 2923, label: "Region", province: "Bulacan" },
      { provinceCode: "PH-CAG", id: 2924, label: "Region", province: "Cagayan" },
      {
        provinceCode: "PH-CAN",
        id: 2925,
        label: "Region",
        province: "Camarines Norte",
      },
      {
        provinceCode: "PH-CAS",
        id: 2926,
        label: "Region",
        province: "Camarines Sur",
      },
      { provinceCode: "PH-CAM", id: 2927, label: "Region", province: "Camiguin" },
      { provinceCode: "PH-CAP", id: 2928, label: "Region", province: "Capiz" },
      {
        provinceCode: "PH-CAT",
        id: 2929,
        label: "Region",
        province: "Catanduanes",
      },
      { provinceCode: "PH-CAV", id: 2930, label: "Region", province: "Cavite" },
      { provinceCode: "PH-CEB", id: 2931, label: "Region", province: "Cebu" },
      { provinceCode: "PH-NCO", id: 2932, label: "Region", province: "Cotabato" },
      {
        provinceCode: "PH-DVO",
        id: 2936,
        label: "Region",
        province: "Davao Occidental",
      },
      {
        provinceCode: "PH-DAO",
        id: 2937,
        label: "Region",
        province: "Davao Oriental",
      },
      {
        provinceCode: "PH-DAV",
        id: 2934,
        label: "Region",
        province: "Davao del Norte",
      },
      {
        provinceCode: "PH-DAS",
        id: 2935,
        label: "Region",
        province: "Davao del Sur",
      },
      {
        provinceCode: "PH-COM",
        id: 2933,
        label: "Region",
        province: "Davaode Oro",
      },
      {
        provinceCode: "PH-DIN",
        id: 2938,
        label: "Region",
        province: "Dinagat Islands",
      },
      {
        provinceCode: "PH-EAS",
        id: 2939,
        label: "Region",
        province: "Eastern Samar",
      },
      { provinceCode: "PH-GUI", id: 2940, label: "Region", province: "Guimaras" },
      { provinceCode: "PH-IFU", id: 2941, label: "Region", province: "Ifugao" },
      {
        provinceCode: "PH-ILN",
        id: 2942,
        label: "Region",
        province: "Ilocos Norte",
      },
      { provinceCode: "PH-ILS", id: 2943, label: "Region", province: "Ilocos Sur" },
      { provinceCode: "PH-ILI", id: 2944, label: "Region", province: "Iloilo" },
      { provinceCode: "PH-ISA", id: 2945, label: "Region", province: "Isabela" },
      { provinceCode: "PH-KAL", id: 2946, label: "Region", province: "Kalinga" },
      { provinceCode: "PH-LUN", id: 2947, label: "Region", province: "LaUnion" },
      { provinceCode: "PH-LAG", id: 2948, label: "Region", province: "Laguna" },
      {
        provinceCode: "PH-LAN",
        id: 2949,
        label: "Region",
        province: "Lanao del Norte",
      },
      {
        provinceCode: "PH-LAS",
        id: 2950,
        label: "Region",
        province: "Lanao del Sur",
      },
      { provinceCode: "PH-LEY", id: 2951, label: "Region", province: "Leyte" },
      {
        provinceCode: "PH-MAG",
        id: 2952,
        label: "Region",
        province: "Maguindanao",
      },
      { provinceCode: "PH-MAD", id: 2953, label: "Region", province: "Marinduque" },
      { provinceCode: "PH-MAS", id: 2954, label: "Region", province: "Masbate" },
      {
        provinceCode: "PH-00",
        id: 2988,
        label: "Region",
        province: "Metro Manila",
      },
      {
        provinceCode: "PH-MSC",
        id: 2955,
        label: "Region",
        province: "Misamis Occidental",
      },
      {
        provinceCode: "PH-MSR",
        id: 2956,
        label: "Region",
        province: "Misamis Oriental",
      },
      {
        provinceCode: "PH-MOU",
        id: 2957,
        label: "Region",
        province: "Mountain Province",
      },
      {
        provinceCode: "PH-NEC",
        id: 2958,
        label: "Region",
        province: "Negros Occidental",
      },
      {
        provinceCode: "PH-NER",
        id: 2959,
        label: "Region",
        province: "Negros Oriental",
      },
      {
        provinceCode: "PH-NSA",
        id: 2960,
        label: "Region",
        province: "Northern Samar",
      },
      {
        provinceCode: "PH-NUE",
        id: 2961,
        label: "Region",
        province: "Nueva Ecija",
      },
      {
        provinceCode: "PH-NUV",
        id: 2962,
        label: "Region",
        province: "Nueva Vizcaya",
      },
      {
        provinceCode: "PH-MDC",
        id: 2963,
        label: "Region",
        province: "Occidental Mindoro",
      },
      {
        provinceCode: "PH-MDR",
        id: 2964,
        label: "Region",
        province: "Oriental Mindoro",
      },
      { provinceCode: "PH-PLW", id: 2965, label: "Region", province: "Palawan" },
      { provinceCode: "PH-PAM", id: 2966, label: "Region", province: "Pampanga" },
      { provinceCode: "PH-PAN", id: 2967, label: "Region", province: "Pangasinan" },
      { provinceCode: "PH-QUE", id: 2968, label: "Region", province: "Quezon" },
      { provinceCode: "PH-QUI", id: 2969, label: "Region", province: "Quirino" },
      { provinceCode: "PH-RIZ", id: 2970, label: "Region", province: "Rizal" },
      { provinceCode: "PH-ROM", id: 2971, label: "Region", province: "Romblon" },
      { provinceCode: "PH-WSA", id: 2972, label: "Region", province: "Samar" },
      { provinceCode: "PH-SAR", id: 2973, label: "Region", province: "Sarangani" },
      { provinceCode: "PH-SIG", id: 2974, label: "Region", province: "Siquijor" },
      { provinceCode: "PH-SOR", id: 2975, label: "Region", province: "Sorsogon" },
      {
        provinceCode: "PH-SCO",
        id: 2976,
        label: "Region",
        province: "South Cotabato",
      },
      {
        provinceCode: "PH-SLE",
        id: 2977,
        label: "Region",
        province: "Southern Leyte",
      },
      {
        provinceCode: "PH-SUK",
        id: 2978,
        label: "Region",
        province: "Sultan Kudarat",
      },
      { provinceCode: "PH-SLU", id: 2979, label: "Region", province: "Sulu" },
      {
        provinceCode: "PH-SUN",
        id: 2980,
        label: "Region",
        province: "Surigao del Norte",
      },
      {
        provinceCode: "PH-SUR",
        id: 2981,
        label: "Region",
        province: "Surigao del Sur",
      },
      { provinceCode: "PH-TAR", id: 2982, label: "Region", province: "Tarlac" },
      { provinceCode: "PH-TAW", id: 2983, label: "Region", province: "Tawi-Tawi" },
      { provinceCode: "PH-ZMB", id: 2984, label: "Region", province: "Zambales" },
      {
        provinceCode: "PH-ZSI",
        id: 2987,
        label: "Region",
        province: "Zamboanga Sibugay",
      },
      {
        provinceCode: "PH-ZAN",
        id: 2985,
        label: "Region",
        province: "Zamboanga del Norte",
      },
      {
        provinceCode: "PH-ZAS",
        id: 2986,
        label: "Region",
        province: "Zamboanga del Sur",
      },
    ],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "PN" as CountryCode,
    id: 168,
    provinceLabel: "Region",
    country: "Pitcairn",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "PL" as CountryCode,
    id: 169,
    provinceLabel: "Region",
    country: "Poland",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "PT" as CountryCode,
    id: 170,
    provinceLabel: "Region",
    country: "Portugal",
    provinces: [
      { provinceCode: "PT-01", id: 2313, label: "Region", province: "Aveiro" },
      { provinceCode: "PT-20", id: 2306, label: "Region", province: "A\u00E7ores" },
      { provinceCode: "PT-02", id: 2304, label: "Region", province: "Beja" },
      { provinceCode: "PT-03", id: 2307, label: "Region", province: "Braga" },
      {
        provinceCode: "PT-04",
        id: 2311,
        label: "Region",
        province: "Bragan\u00E7a",
      },
      {
        provinceCode: "PT-05",
        id: 2312,
        label: "Region",
        province: "Castelo Branco",
      },
      { provinceCode: "PT-06", id: 2308, label: "Region", province: "Coimbra" },
      { provinceCode: "PT-08", id: 2302, label: "Region", province: "Faro" },
      { provinceCode: "PT-09", id: 2297, label: "Region", province: "Guarda" },
      { provinceCode: "PT-10", id: 2295, label: "Region", province: "Leiria" },
      { provinceCode: "PT-11", id: 2310, label: "Region", province: "Lisboa" },
      { provinceCode: "PT-30", id: 2314, label: "Region", province: "Madeira" },
      { provinceCode: "PT-12", id: 2309, label: "Region", province: "Portalegre" },
      { provinceCode: "PT-13", id: 2296, label: "Region", province: "Porto" },
      {
        provinceCode: "PT-14",
        id: 2305,
        label: "Region",
        province: "Santar\u00E9m",
      },
      {
        provinceCode: "PT-15",
        id: 2303,
        label: "Region",
        province: "Set\u00FAbal",
      },
      {
        provinceCode: "PT-16",
        id: 2300,
        label: "Region",
        province: "Viana do Castelo",
      },
      { provinceCode: "PT-17", id: 2298, label: "Region", province: "Vila Real" },
      { provinceCode: "PT-18", id: 2299, label: "Region", province: "Viseu" },
      { provinceCode: "PT-07", id: 2301, label: "Region", province: "\u00C9vora" },
    ],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "QA" as CountryCode,
    id: 171,
    provinceLabel: "Region",
    country: "Qatar",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "CM" as CountryCode,
    id: 172,
    provinceLabel: "Region",
    country: "Republic of Cameroon",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "RE" as CountryCode,
    id: 173,
    provinceLabel: "Region",
    country: "Reunion",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "RO" as CountryCode,
    id: 174,
    provinceLabel: "County",
    country: "Romania",
    provinces: [
      { provinceCode: "AB", id: 2635, label: "County", province: "Alba" },
      { provinceCode: "AR", id: 2621, label: "County", province: "Arad" },
      { provinceCode: "AG", id: 2642, label: "County", province: "Arge\u0219" },
      { provinceCode: "BC", id: 2619, label: "County", province: "Bac\u0103u" },
      { provinceCode: "BH", id: 2622, label: "County", province: "Bihor" },
      {
        provinceCode: "BN",
        id: 2610,
        label: "County",
        province: "Bistri\u021Ba-N\u0103s\u0103ud",
      },
      { provinceCode: "BT", id: 2639, label: "County", province: "Boto\u0219ani" },
      { provinceCode: "BV", id: 2611, label: "County", province: "Bra\u0219ov" },
      { provinceCode: "BR", id: 2629, label: "County", province: "Br\u0103ila" },
      { provinceCode: "B", id: 2636, label: "County", province: "Bucure\u0219ti" },
      { provinceCode: "BZ", id: 2604, label: "County", province: "Buz\u0103u" },
      {
        provinceCode: "CS",
        id: 2615,
        label: "County",
        province: "Cara\u0219-Severin",
      },
      { provinceCode: "CJ", id: 2612, label: "County", province: "Cluj" },
      { provinceCode: "CT", id: 2613, label: "County", province: "Constan\u021Ba" },
      { provinceCode: "CV", id: 2620, label: "County", province: "Covasna" },
      {
        provinceCode: "CL",
        id: 2625,
        label: "County",
        province: "C\u0103l\u0103ra\u0219i",
      },
      { provinceCode: "DJ", id: 2632, label: "County", province: "Dolj" },
      {
        provinceCode: "DB",
        id: 2617,
        label: "County",
        province: "D\u00E2mbovi\u021Ba",
      },
      { provinceCode: "GL", id: 2633, label: "County", province: "Gala\u021Bi" },
      { provinceCode: "GR", id: 2638, label: "County", province: "Giurgiu" },
      { provinceCode: "GJ", id: 2623, label: "County", province: "Gorj" },
      { provinceCode: "HR", id: 2640, label: "County", province: "Harghita" },
      { provinceCode: "HD", id: 2606, label: "County", province: "Hunedoara" },
      { provinceCode: "IL", id: 2634, label: "County", province: "Ialomi\u021Ba" },
      { provinceCode: "IS", id: 2628, label: "County", province: "Ia\u0219i" },
      { provinceCode: "IF", id: 2626, label: "County", province: "Ilfov" },
      { provinceCode: "MM", id: 2618, label: "County", province: "Maramure\u0219" },
      { provinceCode: "MH", id: 2609, label: "County", province: "Mehedin\u021Bi" },
      { provinceCode: "MS", id: 2637, label: "County", province: "Mure\u0219" },
      { provinceCode: "NT", id: 2601, label: "County", province: "Neam\u021B" },
      { provinceCode: "OT", id: 2641, label: "County", province: "Olt" },
      { provinceCode: "PH", id: 2624, label: "County", province: "Prahova" },
      { provinceCode: "SM", id: 2607, label: "County", province: "Satu Mare" },
      { provinceCode: "SB", id: 2616, label: "County", province: "Sibiu" },
      { provinceCode: "SV", id: 2603, label: "County", province: "Suceava" },
      { provinceCode: "SJ", id: 2631, label: "County", province: "S\u0103laj" },
      { provinceCode: "TR", id: 2614, label: "County", province: "Teleorman" },
      { provinceCode: "TM", id: 2602, label: "County", province: "Timi\u0219" },
      { provinceCode: "TL", id: 2627, label: "County", province: "Tulcea" },
      { provinceCode: "VS", id: 2608, label: "County", province: "Vaslui" },
      { provinceCode: "VN", id: 2605, label: "County", province: "Vrancea" },
      { provinceCode: "VL", id: 2630, label: "County", province: "V\u00E2lcea" },
    ],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "RU" as CountryCode,
    id: 175,
    provinceLabel: "Region",
    country: "Russia",
    provinces: [
      { provinceCode: "ALT", id: 2187, label: "Region", province: "Altai Krai" },
      { provinceCode: "AL", id: 2194, label: "Region", province: "Altai Republic" },
      { provinceCode: "AMU", id: 2203, label: "Region", province: "Amur Oblast" },
      {
        provinceCode: "ARK",
        id: 2167,
        label: "Region",
        province: "Arkhangelsk Oblast",
      },
      {
        provinceCode: "AST",
        id: 2185,
        label: "Region",
        province: "Astrakhan Oblast",
      },
      {
        provinceCode: "BEL",
        id: 2175,
        label: "Region",
        province: "Belgorod Oblast",
      },
      {
        provinceCode: "BRY",
        id: 2210,
        label: "Region",
        province: "Bryansk Oblast",
      },
      {
        provinceCode: "CE",
        id: 2223,
        label: "Region",
        province: "Chechen Republic",
      },
      {
        provinceCode: "CHE",
        id: 2161,
        label: "Region",
        province: "Chelyabinsk Oblast",
      },
      {
        provinceCode: "CHU",
        id: 2211,
        label: "Region",
        province: "Chukotka Autonomous Okrug",
      },
      {
        provinceCode: "CU",
        id: 2197,
        label: "Region",
        province: "Chuvash Republic",
      },
      {
        provinceCode: "IRK",
        id: 2227,
        label: "Region",
        province: "Irkutsk Oblast",
      },
      {
        provinceCode: "IVA",
        id: 2176,
        label: "Region",
        province: "Ivanovo Oblast",
      },
      {
        provinceCode: "YEV",
        id: 2168,
        label: "Region",
        province: "Jewish Autonomous Oblast",
      },
      {
        provinceCode: "KB",
        id: 2184,
        label: "Region",
        province: "Kabardino-Balkarian Republic",
      },
      {
        provinceCode: "KGD",
        id: 2181,
        label: "Region",
        province: "Kaliningrad Oblast",
      },
      { provinceCode: "KLU", id: 2174, label: "Region", province: "Kaluga Oblast" },
      {
        provinceCode: "KAM",
        id: 2190,
        label: "Region",
        province: "Kamchatka Krai",
      },
      {
        provinceCode: "KC",
        id: 2200,
        label: "Region",
        province: "Karachay\u2013Cherkess Republic",
      },
      {
        provinceCode: "KEM",
        id: 2196,
        label: "Region",
        province: "Kemerovo Oblast",
      },
      {
        provinceCode: "KHA",
        id: 2229,
        label: "Region",
        province: "Khabarovsk Krai",
      },
      {
        provinceCode: "KHM",
        id: 2157,
        label: "Region",
        province: "Khanty-Mansi Autonomous Okrug",
      },
      { provinceCode: "KIR", id: 2186, label: "Region", province: "Kirov Oblast" },
      { provinceCode: "KO", id: 2208, label: "Region", province: "Komi Republic" },
      {
        provinceCode: "KOS",
        id: 2155,
        label: "Region",
        province: "Kostroma Oblast",
      },
      {
        provinceCode: "KDA",
        id: 2209,
        label: "Region",
        province: "Krasnodar Krai",
      },
      {
        provinceCode: "KYA",
        id: 2169,
        label: "Region",
        province: "Krasnoyarsk Krai",
      },
      { provinceCode: "KGN", id: 2201, label: "Region", province: "Kurgan Oblast" },
      { provinceCode: "KRS", id: 2183, label: "Region", province: "Kursk Oblast" },
      {
        provinceCode: "LEN",
        id: 2165,
        label: "Region",
        province: "Leningrad Oblast",
      },
      {
        provinceCode: "LIP",
        id: 2221,
        label: "Region",
        province: "Lipetsk Oblast",
      },
      {
        provinceCode: "MAG",
        id: 2191,
        label: "Region",
        province: "Magadan Oblast",
      },
      {
        provinceCode: "ME",
        id: 2233,
        label: "Region",
        province: "Mari El Republic",
      },
      { provinceCode: "MOW", id: 2182, label: "Region", province: "Moscow" },
      { provinceCode: "MOS", id: 2212, label: "Region", province: "Moscow Oblast" },
      {
        provinceCode: "MUR",
        id: 2206,
        label: "Region",
        province: "Murmansk Oblast",
      },
      {
        provinceCode: "NIZ",
        id: 2159,
        label: "Region",
        province: "Nizhny Novgorod Oblast",
      },
      {
        provinceCode: "NGR",
        id: 2156,
        label: "Region",
        province: "Novgorod Oblast",
      },
      {
        provinceCode: "NVS",
        id: 2234,
        label: "Region",
        province: "Novosibirsk Oblast",
      },
      { provinceCode: "OMS", id: 2172, label: "Region", province: "Omsk Oblast" },
      {
        provinceCode: "ORE",
        id: 2188,
        label: "Region",
        province: "Orenburg Oblast",
      },
      { provinceCode: "ORL", id: 2180, label: "Region", province: "Oryol Oblast" },
      { provinceCode: "PNZ", id: 2216, label: "Region", province: "Penza Oblast" },
      { provinceCode: "PER", id: 2177, label: "Region", province: "Perm Krai" },
      {
        provinceCode: "PRI",
        id: 2226,
        label: "Region",
        province: "Primorsky Krai",
      },
      { provinceCode: "PSK", id: 2225, label: "Region", province: "Pskov Oblast" },
      {
        provinceCode: "AD",
        id: 2220,
        label: "Region",
        province: "Republic of Adygeya",
      },
      {
        provinceCode: "BA",
        id: 2179,
        label: "Region",
        province: "Republic of Bashkortostan",
      },
      {
        provinceCode: "BU",
        id: 2207,
        label: "Region",
        province: "Republic of Buryatia",
      },
      {
        provinceCode: "DA",
        id: 2173,
        label: "Region",
        province: "Republic of Dagestan",
      },
      {
        provinceCode: "IN",
        id: 2217,
        label: "Region",
        province: "Republic of Ingushetia",
      },
      {
        provinceCode: "KL",
        id: 2170,
        label: "Region",
        province: "Republic of Kalmykia",
      },
      {
        provinceCode: "KR",
        id: 2160,
        label: "Region",
        province: "Republic of Karelia",
      },
      {
        provinceCode: "KK",
        id: 2222,
        label: "Region",
        province: "Republic of Khakassia",
      },
      {
        provinceCode: "MO",
        id: 2219,
        label: "Region",
        province: "Republic of Mordovia",
      },
      {
        provinceCode: "SE",
        id: 2158,
        label: "Region",
        province: "Republic of North Ossetia\u2013Alania",
      },
      {
        provinceCode: "TA",
        id: 2215,
        label: "Region",
        province: "Republic of Tatarstan",
      },
      { provinceCode: "ROS", id: 2154, label: "Region", province: "Rostov Oblast" },
      { provinceCode: "RYA", id: 2218, label: "Region", province: "Ryazan Oblast" },
      {
        provinceCode: "SPE",
        id: 2163,
        label: "Region",
        province: "Saint Petersburg",
      },
      {
        provinceCode: "SA",
        id: 2230,
        label: "Region",
        province: "Sakha Republic (Yakutia)",
      },
      {
        provinceCode: "SAK",
        id: 2171,
        label: "Region",
        province: "Sakhalin Oblast",
      },
      { provinceCode: "SAM", id: 2214, label: "Region", province: "Samara Oblast" },
      {
        provinceCode: "SAR",
        id: 2198,
        label: "Region",
        province: "Saratov Oblast",
      },
      {
        provinceCode: "SMO",
        id: 2193,
        label: "Region",
        province: "Smolensk Oblast",
      },
      {
        provinceCode: "STA",
        id: 2199,
        label: "Region",
        province: "Stavropol Krai",
      },
      {
        provinceCode: "SVE",
        id: 2204,
        label: "Region",
        province: "Sverdlovsk Oblast",
      },
      { provinceCode: "TAM", id: 2228, label: "Region", province: "Tambov Oblast" },
      { provinceCode: "TOM", id: 2178, label: "Region", province: "Tomsk Oblast" },
      { provinceCode: "TUL", id: 2192, label: "Region", province: "Tula Oblast" },
      { provinceCode: "TVE", id: 2205, label: "Region", province: "Tver Oblast" },
      { provinceCode: "TYU", id: 2189, label: "Region", province: "Tyumen Oblast" },
      { provinceCode: "TY", id: 2202, label: "Region", province: "Tyva Republic" },
      { provinceCode: "UD", id: 2195, label: "Region", province: "Udmurtia" },
      {
        provinceCode: "ULY",
        id: 2232,
        label: "Region",
        province: "Ulyanovsk Oblast",
      },
      {
        provinceCode: "VLA",
        id: 2166,
        label: "Region",
        province: "Vladimir Oblast",
      },
      {
        provinceCode: "VGG",
        id: 2162,
        label: "Region",
        province: "Volgograd Oblast",
      },
      {
        provinceCode: "VLG",
        id: 2231,
        label: "Region",
        province: "Vologda Oblast",
      },
      {
        provinceCode: "VOR",
        id: 2164,
        label: "Region",
        province: "Voronezh Oblast",
      },
      {
        provinceCode: "YAN",
        id: 2224,
        label: "Region",
        province: "Yamalo-Nenets Autonomous Okrug",
      },
      {
        provinceCode: "YAR",
        id: 2213,
        label: "Region",
        province: "Yaroslavl Oblast",
      },
      {
        provinceCode: "ZAB",
        id: 2867,
        label: "Region",
        province: "Zabaykalsky Krai",
      },
    ],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "RW" as CountryCode,
    id: 176,
    provinceLabel: "Region",
    country: "Rwanda",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "BL" as CountryCode,
    id: 177,
    provinceLabel: "Region",
    country: "Saint Barth\u00E9lemy",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "SH" as CountryCode,
    id: 178,
    provinceLabel: "Region",
    country: "Saint Helena",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "KN" as CountryCode,
    id: 179,
    provinceLabel: "Region",
    country: "Saint Kitts And Nevis",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "LC" as CountryCode,
    id: 180,
    provinceLabel: "Region",
    country: "Saint Lucia",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "MF" as CountryCode,
    id: 181,
    provinceLabel: "Region",
    country: "Saint Martin",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "PM" as CountryCode,
    id: 182,
    provinceLabel: "Region",
    country: "Saint Pierre And Miquelon",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "WS" as CountryCode,
    id: 183,
    provinceLabel: "Region",
    country: "Samoa",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "SM" as CountryCode,
    id: 184,
    provinceLabel: "Region",
    country: "San Marino",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "ST" as CountryCode,
    id: 185,
    provinceLabel: "Region",
    country: "Sao Tome And Principe",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "SA" as CountryCode,
    id: 186,
    provinceLabel: "Region",
    country: "Saudi Arabia",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "SN" as CountryCode,
    id: 187,
    provinceLabel: "Region",
    country: "Senegal",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "RS" as CountryCode,
    id: 188,
    provinceLabel: "Region",
    country: "Serbia",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "SC" as CountryCode,
    id: 189,
    provinceLabel: "Region",
    country: "Seychelles",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "SL" as CountryCode,
    id: 190,
    provinceLabel: "Region",
    country: "Sierra Leone",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "SG" as CountryCode,
    id: 191,
    provinceLabel: "Region",
    country: "Singapore",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "SX" as CountryCode,
    id: 1000,
    provinceLabel: "Region",
    country: "Sint Maarten",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "SK" as CountryCode,
    id: 192,
    provinceLabel: "Region",
    country: "Slovakia",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "SI" as CountryCode,
    id: 193,
    provinceLabel: "Region",
    country: "Slovenia",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "SB" as CountryCode,
    id: 194,
    provinceLabel: "Region",
    country: "Solomon Islands",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "SO" as CountryCode,
    id: 195,
    provinceLabel: "Region",
    country: "Somalia",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "ZA" as CountryCode,
    id: 196,
    provinceLabel: "Province",
    country: "South Africa",
    provinces: [
      { provinceCode: "EC", id: 2237, label: "Province", province: "Eastern Cape" },
      { provinceCode: "FS", id: 2240, label: "Province", province: "Free State" },
      { provinceCode: "GT", id: 2235, label: "Province", province: "Gauteng" },
      {
        provinceCode: "NL",
        id: 2241,
        label: "Province",
        province: "KwaZulu-Natal",
      },
      { provinceCode: "LP", id: 2243, label: "Province", province: "Limpopo" },
      { provinceCode: "MP", id: 2239, label: "Province", province: "Mpumalanga" },
      { provinceCode: "NW", id: 2238, label: "Province", province: "North West" },
      {
        provinceCode: "NC",
        id: 2242,
        label: "Province",
        province: "Northern Cape",
      },
      { provinceCode: "WC", id: 2236, label: "Province", province: "Western Cape" },
    ],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "GS" as CountryCode,
    id: 197,
    provinceLabel: "Region",
    country: "South Georgia And The South Sandwich Islands",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "KR" as CountryCode,
    id: 198,
    provinceLabel: "Province",
    country: "South Korea",
    provinces: [
      { provinceCode: "KR-26", id: 2848, label: "Province", province: "Busan" },
      { provinceCode: "KR-43", id: 2852, label: "Province", province: "Chungbuk" },
      { provinceCode: "KR-44", id: 2850, label: "Province", province: "Chungnam" },
      { provinceCode: "KR-27", id: 2847, label: "Province", province: "Daegu" },
      { provinceCode: "KR-30", id: 2842, label: "Province", province: "Daejeon" },
      { provinceCode: "KR-42", id: 2836, label: "Province", province: "Gangwon" },
      { provinceCode: "KR-29", id: 2841, label: "Province", province: "Gwangju" },
      { provinceCode: "KR-47", id: 2840, label: "Province", province: "Gyeongbuk" },
      { provinceCode: "KR-41", id: 2838, label: "Province", province: "Gyeonggi" },
      { provinceCode: "KR-48", id: 2843, label: "Province", province: "Gyeongnam" },
      { provinceCode: "KR-28", id: 2851, label: "Province", province: "Incheon" },
      { provinceCode: "KR-49", id: 2837, label: "Province", province: "Jeju" },
      { provinceCode: "KR-45", id: 2839, label: "Province", province: "Jeonbuk" },
      { provinceCode: "KR-46", id: 2844, label: "Province", province: "Jeonnam" },
      { provinceCode: "KR-50", id: 2846, label: "Province", province: "Sejong" },
      { provinceCode: "KR-11", id: 2845, label: "Province", province: "Seoul" },
      { provinceCode: "KR-31", id: 2849, label: "Province", province: "Ulsan" },
    ],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "SS" as CountryCode,
    id: 1001,
    provinceLabel: "State",
    country: "South Sudan",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "ES" as CountryCode,
    id: 199,
    provinceLabel: "Province",
    country: "Spain",
    provinces: [
      { provinceCode: "C", id: 2355, label: "Province", province: "A Coru\u00F1a" },
      { provinceCode: "AB", id: 2357, label: "Province", province: "Albacete" },
      { provinceCode: "A", id: 2351, label: "Province", province: "Alicante" },
      { provinceCode: "AL", id: 2366, label: "Province", province: "Almer\u00EDa" },
      { provinceCode: "O", id: 2349, label: "Province", province: "Asturias" },
      { provinceCode: "BA", id: 2322, label: "Province", province: "Badajoz" },
      { provinceCode: "PM", id: 2363, label: "Province", province: "Balears" },
      { provinceCode: "B", id: 2352, label: "Province", province: "Barcelona" },
      { provinceCode: "BU", id: 2358, label: "Province", province: "Burgos" },
      { provinceCode: "S", id: 2365, label: "Province", province: "Cantabria" },
      {
        provinceCode: "CS",
        id: 2319,
        label: "Province",
        province: "Castell\u00F3n",
      },
      { provinceCode: "CE", id: 2350, label: "Province", province: "Ceuta" },
      { provinceCode: "CR", id: 2348, label: "Province", province: "Ciudad Real" },
      { provinceCode: "CU", id: 2345, label: "Province", province: "Cuenca" },
      { provinceCode: "CC", id: 2333, label: "Province", province: "C\u00E1ceres" },
      { provinceCode: "CA", id: 2330, label: "Province", province: "C\u00E1diz" },
      { provinceCode: "CO", id: 2315, label: "Province", province: "C\u00F3rdoba" },
      { provinceCode: "GI", id: 2336, label: "Province", province: "Girona" },
      { provinceCode: "GR", id: 2317, label: "Province", province: "Granada" },
      { provinceCode: "GU", id: 2323, label: "Province", province: "Guadalajara" },
      {
        provinceCode: "SS",
        id: 2342,
        label: "Province",
        province: "Guip\u00FAzcoa",
      },
      { provinceCode: "H", id: 2356, label: "Province", province: "Huelva" },
      { provinceCode: "HU", id: 2339, label: "Province", province: "Huesca" },
      { provinceCode: "J", id: 2326, label: "Province", province: "Ja\u00E9n" },
      { provinceCode: "LO", id: 2364, label: "Province", province: "La Rioja" },
      { provinceCode: "GC", id: 2341, label: "Province", province: "Las Palmas" },
      { provinceCode: "LE", id: 2338, label: "Province", province: "Le\u00F3n" },
      { provinceCode: "L", id: 2331, label: "Province", province: "Lleida" },
      { provinceCode: "LU", id: 2353, label: "Province", province: "Lugo" },
      { provinceCode: "M", id: 2361, label: "Province", province: "Madrid" },
      { provinceCode: "ML", id: 2359, label: "Province", province: "Melilla" },
      { provinceCode: "MU", id: 2347, label: "Province", province: "Murcia" },
      { provinceCode: "MA", id: 2327, label: "Province", province: "M\u00E1laga" },
      { provinceCode: "NA", id: 2320, label: "Province", province: "Navarra" },
      { provinceCode: "OR", id: 2343, label: "Province", province: "Ourense" },
      { provinceCode: "P", id: 2318, label: "Province", province: "Palencia" },
      { provinceCode: "PO", id: 2316, label: "Province", province: "Pontevedra" },
      { provinceCode: "SA", id: 2335, label: "Province", province: "Salamanca" },
      {
        provinceCode: "TF",
        id: 2325,
        label: "Province",
        province: "Santa Cruz de Tenerife",
      },
      { provinceCode: "SG", id: 2354, label: "Province", province: "Segovia" },
      { provinceCode: "SE", id: 2362, label: "Province", province: "Sevilla" },
      { provinceCode: "SO", id: 2324, label: "Province", province: "Soria" },
      { provinceCode: "T", id: 2346, label: "Province", province: "Tarragona" },
      { provinceCode: "TE", id: 2321, label: "Province", province: "Teruel" },
      { provinceCode: "TO", id: 2328, label: "Province", province: "Toledo" },
      { provinceCode: "V", id: 2329, label: "Province", province: "Valencia" },
      { provinceCode: "VA", id: 2340, label: "Province", province: "Valladolid" },
      { provinceCode: "BI", id: 2337, label: "Province", province: "Vizcaya" },
      { provinceCode: "ZA", id: 2344, label: "Province", province: "Zamora" },
      { provinceCode: "Z", id: 2332, label: "Province", province: "Zaragoza" },
      { provinceCode: "VI", id: 2334, label: "Province", province: "\u00C1lava" },
      { provinceCode: "AV", id: 2360, label: "Province", province: "\u00C1vila" },
    ],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "LK" as CountryCode,
    id: 200,
    provinceLabel: "Region",
    country: "Sri Lanka",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "VC" as CountryCode,
    id: 201,
    provinceLabel: "Region",
    country: "St. Vincent",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "SD" as CountryCode,
    id: 202,
    provinceLabel: "Region",
    country: "Sudan",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "SR" as CountryCode,
    id: 203,
    provinceLabel: "Region",
    country: "Suriname",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "SJ" as CountryCode,
    id: 204,
    provinceLabel: "Region",
    country: "Svalbard And Jan Mayen",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "SZ" as CountryCode,
    id: 205,
    provinceLabel: "Region",
    country: "Swaziland",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "SE" as CountryCode,
    id: 206,
    provinceLabel: "Region",
    country: "Sweden",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "CH" as CountryCode,
    id: 207,
    provinceLabel: "Region",
    country: "Switzerland",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "SY" as CountryCode,
    id: 208,
    provinceLabel: "Region",
    country: "Syria",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "TW" as CountryCode,
    id: 209,
    provinceLabel: "Region",
    country: "Taiwan",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "TJ" as CountryCode,
    id: 210,
    provinceLabel: "Region",
    country: "Tajikistan",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "TZ" as CountryCode,
    id: 211,
    provinceLabel: "Region",
    country: "Tanzania, United Republic Of",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "TH" as CountryCode,
    id: 212,
    provinceLabel: "Province",
    country: "Thailand",
    provinces: [
      {
        provinceCode: "ACR",
        id: 2779,
        label: "Province",
        province: "Amnat Charoen",
      },
      { provinceCode: "ATG", id: 2787, label: "Province", province: "Ang Thong" },
      { provinceCode: "BKK", id: 2789, label: "Province", province: "Bangkok" },
      { provinceCode: "BKN", id: 2786, label: "Province", province: "Bueng Kan" },
      { provinceCode: "BRM", id: 2809, label: "Province", province: "Buriram" },
      {
        provinceCode: "CCO",
        id: 2769,
        label: "Province",
        province: "Chachoengsao",
      },
      { provinceCode: "CNT", id: 2823, label: "Province", province: "Chai Nat" },
      { provinceCode: "CPM", id: 2815, label: "Province", province: "Chaiyaphum" },
      { provinceCode: "CTI", id: 2767, label: "Province", province: "Chanthaburi" },
      { provinceCode: "CMI", id: 2832, label: "Province", province: "Chiang Mai" },
      { provinceCode: "CRI", id: 2777, label: "Province", province: "Chiang Rai" },
      { provinceCode: "CBI", id: 2801, label: "Province", province: "Chon Buri" },
      { provinceCode: "CPN", id: 2780, label: "Province", province: "Chumphon" },
      { provinceCode: "KSN", id: 2778, label: "Province", province: "Kalasin" },
      {
        provinceCode: "KPT",
        id: 2776,
        label: "Province",
        province: "Kamphaeng Phet",
      },
      {
        provinceCode: "KRI",
        id: 2835,
        label: "Province",
        province: "Kanchanaburi",
      },
      { provinceCode: "KKN", id: 2782, label: "Province", province: "Khon Kaen" },
      { provinceCode: "KBI", id: 2804, label: "Province", province: "Krabi" },
      { provinceCode: "LPG", id: 2829, label: "Province", province: "Lampang" },
      { provinceCode: "LPN", id: 2763, label: "Province", province: "Lamphun" },
      { provinceCode: "LEI", id: 2796, label: "Province", province: "Loei" },
      { provinceCode: "LRI", id: 2800, label: "Province", province: "Lopburi" },
      {
        provinceCode: "MSN",
        id: 2781,
        label: "Province",
        province: "Mae Hong Son",
      },
      {
        provinceCode: "MKM",
        id: 2795,
        label: "Province",
        province: "Maha Sarakham",
      },
      { provinceCode: "MDH", id: 2761, label: "Province", province: "Mukdahan" },
      {
        provinceCode: "NYK",
        id: 2814,
        label: "Province",
        province: "Nakhon Nayok",
      },
      {
        provinceCode: "NPT",
        id: 2793,
        label: "Province",
        province: "Nakhon Pathom",
      },
      {
        provinceCode: "NPM",
        id: 2811,
        label: "Province",
        province: "Nakhon Phanom",
      },
      {
        provinceCode: "NMA",
        id: 2774,
        label: "Province",
        province: "Nakhon Ratchasima",
      },
      {
        provinceCode: "NSN",
        id: 2830,
        label: "Province",
        province: "Nakhon Sawan",
      },
      {
        provinceCode: "NRT",
        id: 2807,
        label: "Province",
        province: "Nakhon Si Thammarat",
      },
      { provinceCode: "NAN", id: 2765, label: "Province", province: "Nan" },
      { provinceCode: "NWT", id: 2813, label: "Province", province: "Narathiwat" },
      {
        provinceCode: "NBP",
        id: 2817,
        label: "Province",
        province: "Nong Bua Lam Phu",
      },
      { provinceCode: "NKI", id: 2821, label: "Province", province: "Nong Khai" },
      { provinceCode: "NBI", id: 2806, label: "Province", province: "Nonthaburi" },
      {
        provinceCode: "PTE",
        id: 2803,
        label: "Province",
        province: "Pathum Thani",
      },
      { provinceCode: "PTN", id: 2771, label: "Province", province: "Pattani" },
      { provinceCode: "PNA", id: 2818, label: "Province", province: "Phang Nga" },
      { provinceCode: "PLG", id: 2770, label: "Province", province: "Phatthalung" },
      { provinceCode: "PYO", id: 2812, label: "Province", province: "Phayao" },
      { provinceCode: "PNB", id: 2819, label: "Province", province: "Phetchabun" },
      { provinceCode: "PBI", id: 2773, label: "Province", province: "Phetchaburi" },
      { provinceCode: "PCT", id: 2759, label: "Province", province: "Phichit" },
      { provinceCode: "PLK", id: 2766, label: "Province", province: "Phitsanulok" },
      {
        provinceCode: "AYA",
        id: 2785,
        label: "Province",
        province: "Phra Nakhon Si Ayutthaya",
      },
      { provinceCode: "PRE", id: 2799, label: "Province", province: "Phrae" },
      { provinceCode: "PKT", id: 2828, label: "Province", province: "Phuket" },
      { provinceCode: "PRI", id: 2768, label: "Province", province: "Prachinburi" },
      {
        provinceCode: "PKN",
        id: 2760,
        label: "Province",
        province: "Prachuap Khiri Khan",
      },
      { provinceCode: "RNG", id: 2820, label: "Province", province: "Ranong" },
      { provinceCode: "RBR", id: 2791, label: "Province", province: "Ratchaburi" },
      { provinceCode: "RYG", id: 2825, label: "Province", province: "Rayong" },
      { provinceCode: "RET", id: 2790, label: "Province", province: "Roi Et" },
      { provinceCode: "SKW", id: 2788, label: "Province", province: "Sa Kaeo" },
      {
        provinceCode: "SNK",
        id: 2772,
        label: "Province",
        province: "Sakon Nakhon",
      },
      {
        provinceCode: "SPK",
        id: 2831,
        label: "Province",
        province: "Samut Prakan",
      },
      {
        provinceCode: "SKN",
        id: 2833,
        label: "Province",
        province: "Samut Sakhon",
      },
      {
        provinceCode: "SKM",
        id: 2764,
        label: "Province",
        province: "Samut Songkhram",
      },
      { provinceCode: "SRI", id: 2824, label: "Province", province: "Saraburi" },
      { provinceCode: "STN", id: 2784, label: "Province", province: "Satun" },
      { provinceCode: "SBR", id: 2808, label: "Province", province: "Sing Buri" },
      { provinceCode: "SSK", id: 2783, label: "Province", province: "Sisaket" },
      { provinceCode: "SKA", id: 2775, label: "Province", province: "Songkhla" },
      { provinceCode: "STI", id: 2798, label: "Province", province: "Sukhothai" },
      { provinceCode: "SPB", id: 2802, label: "Province", province: "Suphan Buri" },
      { provinceCode: "SNI", id: 2794, label: "Province", province: "Surat Thani" },
      { provinceCode: "SRN", id: 2834, label: "Province", province: "Surin" },
      { provinceCode: "TAK", id: 2810, label: "Province", province: "Tak" },
      { provinceCode: "TRG", id: 2827, label: "Province", province: "Trang" },
      { provinceCode: "TRT", id: 2816, label: "Province", province: "Trat" },
      {
        provinceCode: "UBN",
        id: 2762,
        label: "Province",
        province: "Ubon Ratchathani",
      },
      { provinceCode: "UDN", id: 2797, label: "Province", province: "Udon Thani" },
      { provinceCode: "UTI", id: 2792, label: "Province", province: "Uthai Thani" },
      { provinceCode: "UTD", id: 2826, label: "Province", province: "Uttaradit" },
      { provinceCode: "YLA", id: 2805, label: "Province", province: "Yala" },
      { provinceCode: "YST", id: 2822, label: "Province", province: "Yasothon" },
    ],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "TL" as CountryCode,
    id: 213,
    provinceLabel: "Region",
    country: "Timor Leste",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "TG" as CountryCode,
    id: 214,
    provinceLabel: "Region",
    country: "Togo",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "TK" as CountryCode,
    id: 215,
    provinceLabel: "Region",
    country: "Tokelau",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "TO" as CountryCode,
    id: 216,
    provinceLabel: "Region",
    country: "Tonga",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "TT" as CountryCode,
    id: 217,
    provinceLabel: "Region",
    country: "Trinidad and Tobago",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "TN" as CountryCode,
    id: 218,
    provinceLabel: "Region",
    country: "Tunisia",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "TR" as CountryCode,
    id: 219,
    provinceLabel: "Region",
    country: "Turkey",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "TM" as CountryCode,
    id: 220,
    provinceLabel: "Region",
    country: "Turkmenistan",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "TC" as CountryCode,
    id: 221,
    provinceLabel: "Region",
    country: "Turks and Caicos Islands",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "TV" as CountryCode,
    id: 222,
    provinceLabel: "Region",
    country: "Tuvalu",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "UG" as CountryCode,
    id: 223,
    provinceLabel: "Region",
    country: "Uganda",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "UA" as CountryCode,
    id: 224,
    provinceLabel: "Region",
    country: "Ukraine",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "AE" as CountryCode,
    id: 225,
    provinceLabel: "Emirate",
    country: "United Arab Emirates",
    provinces: [
      { provinceCode: "AZ", id: 2016, label: "Region", province: "Abu Dhabi" },
      { provinceCode: "AJ", id: 2015, label: "Region", province: "Ajman" },
      { provinceCode: "DU", id: 2018, label: "Region", province: "Dubai" },
      { provinceCode: "FU", id: 2017, label: "Region", province: "Fujairah" },
      { provinceCode: "RK", id: 2020, label: "Region", province: "Ras al-Khaimah" },
      { provinceCode: "SH", id: 2019, label: "Region", province: "Sharjah" },
      { provinceCode: "UQ", id: 2021, label: "Region", province: "Umm al-Quwain" },
    ],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "GB" as CountryCode,
    id: 226,
    provinceLabel: "Region",
    country: "United Kingdom",
    provinces: [
      { provinceCode: "BFP", id: 3000, label: "Region", province: "British Forces" },
      { provinceCode: "ENG", id: 3001, label: "Region", province: "England" },
      { provinceCode: "NIR", id: 3002, label: "Region", province: "Northern Ireland" },
      { provinceCode: "SCT", id: 3003, label: "Region", province: "Scotland" },
      { provinceCode: "WLS", id: 3004, label: "Region", province: "Wales" },
    ],
    zipLabel: "Postcode",
    zipPlaceholder: "Postcode",
    zipRequired: true,
  },
  {
    countryCode: "US" as CountryCode,
    id: 227,
    provinceLabel: "State",
    country: "United States",
    provinces: [
      { provinceCode: "AL", id: 2138, label: "State", province: "Alabama" },
      { provinceCode: "AK", id: 2133, label: "State", province: "Alaska" },
      { provinceCode: "AS", id: 2115, label: "State", province: "American Samoa" },
      { provinceCode: "AZ", id: 2110, label: "State", province: "Arizona" },
      { provinceCode: "AR", id: 2099, label: "State", province: "Arkansas" },
      {
        provinceCode: "AA",
        id: 2142,
        label: "State",
        province: "Armed Forces Americas",
      },
      {
        provinceCode: "AE",
        id: 2150,
        label: "State",
        province: "Armed Forces Europe",
      },
      {
        provinceCode: "AP",
        id: 2135,
        label: "State",
        province: "Armed Forces Pacific",
      },
      { provinceCode: "CA", id: 2119, label: "State", province: "California" },
      { provinceCode: "CO", id: 2143, label: "State", province: "Colorado" },
      { provinceCode: "CT", id: 2117, label: "State", province: "Connecticut" },
      { provinceCode: "DE", id: 2096, label: "State", province: "Delaware" },
      {
        provinceCode: "DC",
        id: 2102,
        label: "State",
        province: "District of Columbia",
      },
      {
        provinceCode: "FM",
        id: 2108,
        label: "State",
        province: "Federated States of Micronesia",
      },
      { provinceCode: "FL", id: 2130, label: "State", province: "Florida" },
      { provinceCode: "GA", id: 2126, label: "State", province: "Georgia" },
      { provinceCode: "GU", id: 2146, label: "State", province: "Guam" },
      { provinceCode: "HI", id: 2131, label: "State", province: "Hawaii" },
      { provinceCode: "ID", id: 2106, label: "State", province: "Idaho" },
      { provinceCode: "IL", id: 2098, label: "State", province: "Illinois" },
      { provinceCode: "IN", id: 2103, label: "State", province: "Indiana" },
      { provinceCode: "IA", id: 2111, label: "State", province: "Iowa" },
      { provinceCode: "KS", id: 2113, label: "State", province: "Kansas" },
      { provinceCode: "KY", id: 2132, label: "State", province: "Kentucky" },
      { provinceCode: "LA", id: 2105, label: "State", province: "Louisiana" },
      { provinceCode: "ME", id: 2153, label: "State", province: "Maine" },
      {
        provinceCode: "MH",
        id: 2123,
        label: "State",
        province: "Marshall Islands",
      },
      { provinceCode: "MD", id: 2104, label: "State", province: "Maryland" },
      { provinceCode: "MA", id: 2120, label: "State", province: "Massachusetts" },
      { provinceCode: "MI", id: 2112, label: "State", province: "Michigan" },
      { provinceCode: "MN", id: 2097, label: "State", province: "Minnesota" },
      { provinceCode: "MS", id: 2092, label: "State", province: "Mississippi" },
      { provinceCode: "MO", id: 2136, label: "State", province: "Missouri" },
      { provinceCode: "MT", id: 2118, label: "State", province: "Montana" },
      { provinceCode: "NE", id: 2134, label: "State", province: "Nebraska" },
      { provinceCode: "NV", id: 2152, label: "State", province: "Nevada" },
      { provinceCode: "NH", id: 2101, label: "State", province: "New Hampshire" },
      { provinceCode: "NJ", id: 2144, label: "State", province: "New Jersey" },
      { provinceCode: "NM", id: 2100, label: "State", province: "New Mexico" },
      { provinceCode: "NY", id: 2149, label: "State", province: "New York" },
      { provinceCode: "NC", id: 2148, label: "State", province: "North Carolina" },
      { provinceCode: "ND", id: 2127, label: "State", province: "North Dakota" },
      {
        provinceCode: "MP",
        id: 2094,
        label: "State",
        province: "Northern Mariana Islands",
      },
      { provinceCode: "OH", id: 2137, label: "State", province: "Ohio" },
      { provinceCode: "OK", id: 2095, label: "State", province: "Oklahoma" },
      { provinceCode: "OR", id: 2116, label: "State", province: "Oregon" },
      { provinceCode: "PW", id: 2093, label: "State", province: "Palau" },
      { provinceCode: "PA", id: 2128, label: "State", province: "Pennsylvania" },
      { provinceCode: "PR", id: 2121, label: "State", province: "Puerto Rico" },
      { provinceCode: "RI", id: 2139, label: "State", province: "Rhode Island" },
      { provinceCode: "SC", id: 2122, label: "State", province: "South Carolina" },
      { provinceCode: "SD", id: 2141, label: "State", province: "South Dakota" },
      { provinceCode: "TN", id: 2109, label: "State", province: "Tennessee" },
      { provinceCode: "TX", id: 2151, label: "State", province: "Texas" },
      { provinceCode: "UT", id: 2114, label: "State", province: "Utah" },
      { provinceCode: "VT", id: 2125, label: "State", province: "Vermont" },
      { provinceCode: "VI", id: 2140, label: "State", province: "Virgin Islands" },
      { provinceCode: "VA", id: 2145, label: "State", province: "Virginia" },
      { provinceCode: "WA", id: 2147, label: "State", province: "Washington" },
      { provinceCode: "WV", id: 2129, label: "State", province: "West Virginia" },
      { provinceCode: "WI", id: 2124, label: "State", province: "Wisconsin" },
      { provinceCode: "WY", id: 2107, label: "State", province: "Wyoming" },
    ],
    zipLabel: "Zip code",
    zipPlaceholder: "Zip code",
    zipRequired: true,
  },
  {
    countryCode: "UM" as CountryCode,
    id: 228,
    provinceLabel: "State",
    country: "United States Minor Outlying Islands",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "UY" as CountryCode,
    id: 229,
    provinceLabel: "Region",
    country: "Uruguay",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "UZ" as CountryCode,
    id: 230,
    provinceLabel: "Province",
    country: "Uzbekistan",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "VU" as CountryCode,
    id: 231,
    provinceLabel: "Region",
    country: "Vanuatu",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "VE" as CountryCode,
    id: 232,
    provinceLabel: "Region",
    country: "Venezuela",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "VN" as CountryCode,
    id: 233,
    provinceLabel: "Region",
    country: "Vietnam",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "VG" as CountryCode,
    id: 234,
    provinceLabel: "Region",
    country: "Virgin Islands, British",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "WF" as CountryCode,
    id: 235,
    provinceLabel: "Region",
    country: "Wallis And Futuna",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "EH" as CountryCode,
    id: 236,
    provinceLabel: "Region",
    country: "Western Sahara",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "YE" as CountryCode,
    id: 237,
    provinceLabel: "Region",
    country: "Yemen",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "ZM" as CountryCode,
    id: 238,
    provinceLabel: "Province",
    country: "Zambia",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "ZW" as CountryCode,
    id: 239,
    provinceLabel: "Region",
    country: "Zimbabwe",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "TA" as CountryCode,
    id: 240,
    provinceLabel: "Region",
    country: "Tristan da Cunha",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
  {
    countryCode: "AC" as CountryCode,
    id: 241,
    provinceLabel: "Region",
    country: "Ascension Island",
    provinces: [],
    zipLabel: "Postal code",
    zipPlaceholder: "Postal code",
    zipRequired: true,
  },
];

export const countryFlagOptionList = [
  {
    label: "Afghanistan (+93)",
    value: CountryCode.Af,
  },
  {
    label: "Albania (+355)",
    value: CountryCode.Ax,
  },
  {
    label: "Algeria (+213)",
    value: CountryCode.Al,
  },
  {
    label: "Andorra (+376)",
    value: CountryCode.Dz,
  },
  {
    label: "Angola (+244)",
    value: CountryCode.Ao,
  },
  {
    label: "Anguilla (+1)",
    value: CountryCode.Ai,
  },
  {
    label: "Antigua & Barbuda (+1)",
    value: CountryCode.Ag,
  },
  {
    label: "Argentina (+54)",
    value: CountryCode.Ar,
  },
  {
    label: "Armenia (+374)",
    value: CountryCode.Am,
  },
  {
    label: "Aruba (+297)",
    value: CountryCode.Aw,
  },
  {
    label: "Ascension Island (+247)",
    value: CountryCode.Ac,
  },
  {
    label: "Australia (+61)",
    value: CountryCode.Au,
  },
  {
    label: "Austria (+43)",
    value: CountryCode.At,
  },
  {
    label: "Azerbaijan (+994)",
    value: CountryCode.Az,
  },
  {
    label: "Bahamas (+1)",
    value: CountryCode.Bs,
  },
  {
    label: "Bahrain (+973)",
    value: CountryCode.Bh,
  },
  {
    label: "Bangladesh (+880)",
    value: CountryCode.Bd,
  },
  {
    label: "Barbados (+1)",
    value: CountryCode.Bb,
  },
  {
    label: "Belarus (+375)",
    value: CountryCode.By,
  },
  {
    label: "Belgium (+32)",
    value: CountryCode.Be,
  },
  {
    label: "Belize (+501)",
    value: CountryCode.Bz,
  },
  {
    label: "Bermuda (+1)",
    value: CountryCode.Bm,
  },
  {
    label: "Bhutan (+975)",
    value: CountryCode.Bt,
  },
  {
    label: "Bolivia (+591)",
    value: CountryCode.Bo,
  },
  {
    label: "Bosnia & Herzegovina (+387)",
    value: CountryCode.Ba,
  },
  {
    label: "Botswana (+267)",
    value: CountryCode.Bw,
  },
  {
    label: "Brazil (+55)",
    value: CountryCode.Br,
  },
  {
    label: "British Indian Ocean Territory (+246)",
    value: CountryCode.Io,
  },
  {
    label: "Brunei (+673)",
    value: CountryCode.Bn,
  },
  {
    label: "Bulgaria (+359)",
    value: CountryCode.Bg,
  },
  {
    label: "Burkina Faso (+226)",
    value: CountryCode.Bf,
  },
  {
    label: "Burundi (+257)",
    value: CountryCode.Bi,
  },
  {
    label: "Cambodia (+855)",
    value: CountryCode.Kh,
  },
  {
    label: "Canada (+1)",
    value: CountryCode.Ca,
  },
  {
    label: "Caribbean Netherlands (+599)",
    value: CountryCode.Bq,
  },
  {
    label: "Cayman Islands (+1)",
    value: CountryCode.Ky,
  },
  {
    label: "Central African Republic (+236)",
    value: CountryCode.Cf,
  },
  {
    label: "Chad (+235)",
    value: CountryCode.Td,
  },
  {
    label: "Chile (+56)",
    value: CountryCode.Cl,
  },
  {
    label: "China (+86)",
    value: CountryCode.Cn,
  },
  {
    label: "Christmas Island (+61)",
    value: CountryCode.Cx,
  },
  {
    label: "Cocos (Keeling) Islands (+891)",
    value: CountryCode.Cc,
  },
  {
    label: "Colombia (+57)",
    value: CountryCode.Co,
  },
  {
    label: "Comoros (+269)",
    value: CountryCode.Km,
  },
  {
    label: "Congo - Brazzaville (+242)",
    value: CountryCode.Cg,
  },
  {
    label: "Congo - Kinshasa (+243)",
    value: CountryCode.Cd,
  },
  {
    label: "Cook Islands (+682)",
    value: CountryCode.Ck,
  },
  {
    label: "Costa Rica (+506)",
    value: CountryCode.Cr,
  },
  {
    label: "Croatia (+385)",
    value: CountryCode.Hr,
  },
  {
    label: "Curaçao (+599)",
    value: CountryCode.Cw,
  },
  {
    label: "Cyprus (+357)",
    value: CountryCode.Cy,
  },
  {
    label: "Czechia (+420)",
    value: CountryCode.Cz,
  },
  {
    label: "Côte d'Ivoire (+225)",
    value: CountryCode.Ci,
  },
  {
    label: "Denmark (+45)",
    value: CountryCode.Dk,
  },
  {
    label: "Djibouti (+253)",
    value: CountryCode.Dj,
  },
  {
    label: "Dominica (+1)",
    value: CountryCode.Dm,
  },
  {
    label: "Dominican Republic (+1)",
    value: CountryCode.Do,
  },
  {
    label: "Ecuador (+593)",
    value: CountryCode.Ec,
  },
  {
    label: "Egypt (+20)",
    value: CountryCode.Eg,
  },
  {
    label: "El Salvador (+503)",
    value: CountryCode.Sv,
  },
  {
    label: "Equatorial Guinea (+240)",
    value: CountryCode.Gq,
  },
  {
    label: "Eritrea (+291)",
    value: CountryCode.Er,
  },
  {
    label: "Estonia (+372)",
    value: CountryCode.Ee,
  },
  {
    label: "Eswatini (+268)",
    value: CountryCode.Sz,
  },
  {
    label: "Ethiopia (+251)",
    value: CountryCode.Et,
  },
  {
    label: "Falkland Islands (+500)",
    value: CountryCode.Fk,
  },
  {
    label: "Faroe Islands (+298)",
    value: CountryCode.Fo,
  },
  {
    label: "Fiji (+679)",
    value: CountryCode.Fj,
  },
  {
    label: "Finland (+358)",
    value: CountryCode.Fi,
  },
  {
    label: "France (+33)",
    value: CountryCode.Fr,
  },
  {
    label: "French Guiana (+594)",
    value: CountryCode.Gf,
  },
  {
    label: "French Polynesia (+689)",
    value: CountryCode.Pf,
  },
  {
    label: "French Southern Territories (+262)",
    value: CountryCode.Tf,
  },
  {
    label: "Gabon (+241)",
    value: CountryCode.Ga,
  },
  {
    label: "Gambia (+220)",
    value: CountryCode.Gm,
  },
  {
    label: "Georgia (+995)",
    value: CountryCode.Ge,
  },
  {
    label: "Germany (+49)",
    value: CountryCode.De,
  },
  {
    label: "Ghana (+233)",
    value: CountryCode.Gh,
  },
  {
    label: "Gibraltar (+350)",
    value: CountryCode.Gi,
  },
  {
    label: "Greece (+30)",
    value: CountryCode.Gr,
  },
  {
    label: "Greenland (+299)",
    value: CountryCode.Gl,
  },
  {
    label: "Grenada (+1)",
    value: CountryCode.Gd,
  },
  {
    label: "Guadeloupe (+590)",
    value: CountryCode.Gp,
  },
  {
    label: "Guatemala (+502)",
    value: CountryCode.Gt,
  },
  {
    label: "Guernsey (+44)",
    value: CountryCode.Gg,
  },
  {
    label: "Guinea (+224)",
    value: CountryCode.Gn,
  },
  {
    label: "Guyana (+592)",
    value: CountryCode.Gy,
  },
  {
    label: "Haiti (+509)",
    value: CountryCode.Ht,
  },
  {
    label: "Honduras (+504)",
    value: CountryCode.Hn,
  },
  {
    label: "Hong Kong SAR (+852)",
    value: CountryCode.Hk,
  },
  {
    label: "Hungary (+36)",
    value: CountryCode.Hu,
  },
  {
    label: "Iceland (+354)",
    value: CountryCode.Is,
  },
  {
    label: "India (+91)",
    value: CountryCode.In,
  },
  {
    label: "Indonesia (+62)",
    value: CountryCode.Id,
  },
  {
    label: "Ireland (+353)",
    value: CountryCode.Ie,
  },
  {
    label: "Isle of Man (+44)",
    value: CountryCode.Im,
  },
  {
    label: "Israel (+972)",
    value: CountryCode.Il,
  },
  {
    label: "Italy (+39)",
    value: CountryCode.It,
  },
  {
    label: "Jamaica (+1)",
    value: CountryCode.Jm,
  },
  {
    label: "Japan (+81)",
    value: CountryCode.Jp,
  },
  {
    label: "Jersey (+44)",
    value: CountryCode.Je,
  },
  {
    label: "Jordan (+962)",
    value: CountryCode.Jo,
  },
  {
    label: "Kazakhstan (+7)",
    value: CountryCode.Kz,
  },
  {
    label: "Kenya (+254)",
    value: CountryCode.Ke,
  },
  {
    label: "Kiribati (+686)",
    value: CountryCode.Ki,
  },
  {
    label: "Kosovo (+383)",
    value: CountryCode.Xk,
  },
  {
    label: "Kuwait (+965)",
    value: CountryCode.Kw,
  },
  {
    label: "Kyrgyzstan (+996)",
    value: CountryCode.Kg,
  },
  {
    label: "Laos (+856)",
    value: CountryCode.La,
  },
  {
    label: "Latvia (+371)",
    value: CountryCode.Lv,
  },
  {
    label: "Lebanon (+961)",
    value: CountryCode.Lb,
  },
  {
    label: "Lesotho (+266)",
    value: CountryCode.Ls,
  },
  {
    label: "Liberia (+231)",
    value: CountryCode.Lr,
  },
  {
    label: "Libya (+218)",
    value: CountryCode.Ly,
  },
  {
    label: "Liechtenstein (+423)",
    value: CountryCode.Li,
  },
  {
    label: "Lithuania (+370)",
    value: CountryCode.Lt,
  },
  {
    label: "Luxembourg (+352)",
    value: CountryCode.Lu,
  },
  {
    label: "Macao SAR (+853)",
    value: CountryCode.Mo,
  },
  {
    label: "Madagascar (+261)",
    value: CountryCode.Mg,
  },
  {
    label: "Malawi (+265)",
    value: CountryCode.Mw,
  },
  {
    label: "Malaysia (+60)",
    value: CountryCode.My,
  },
  {
    label: "Maldives (+960)",
    value: CountryCode.Mv,
  },
  {
    label: "Mali (+223)",
    value: CountryCode.Ml,
  },
  {
    label: "Malta (+356)",
    value: CountryCode.Mt,
  },
  {
    label: "Martinique (+596)",
    value: CountryCode.Mq,
  },
  {
    label: "Mauritania (+222)",
    value: CountryCode.Mr,
  },
  {
    label: "Mauritius (+230)",
    value: CountryCode.Mu,
  },
  {
    label: "Mayotte (+262)",
    value: CountryCode.Yt,
  },
  {
    label: "Mexico (+52)",
    value: CountryCode.Mx,
  },
  {
    label: "Moldova (+373)",
    value: CountryCode.Md,
  },
  {
    label: "Monaco (+377)",
    value: CountryCode.Mc,
  },
  {
    label: "Mongolia (+976)",
    value: CountryCode.Mn,
  },
  {
    label: "Montenegro (+382)",
    value: CountryCode.Me,
  },
  {
    label: "Montserrat (+1)",
    value: CountryCode.Ms,
  },
  {
    label: "Morocco (+212)",
    value: CountryCode.Ma,
  },
  {
    label: "Mozambique (+258)",
    value: CountryCode.Mz,
  },
  {
    label: "Myanmar (Burma) (+95)",
    value: CountryCode.Mm,
  },
  {
    label: "Namibia (+264)",
    value: CountryCode.Na,
  },
  {
    label: "Nauru (+674)",
    value: CountryCode.Nr,
  },
  {
    label: "Nepal (+977)",
    value: CountryCode.Np,
  },
  {
    label: "Netherlands (+31)",
    value: CountryCode.Nl,
  },
  {
    label: "New Caledonia (+687)",
    value: CountryCode.Nc,
  },
  {
    label: "New Zealand (+64)",
    value: CountryCode.Nz,
  },
  {
    label: "Nicaragua (+505)",
    value: CountryCode.Ni,
  },
  {
    label: "Niger (+227)",
    value: CountryCode.Ne,
  },
  {
    label: "Nigeria (+234)",
    value: CountryCode.Ng,
  },
  {
    label: "Niue (+683)",
    value: CountryCode.Nu,
  },
  {
    label: "Norfolk Island (+672)",
    value: CountryCode.Nf,
  },
  {
    label: "North Macedonia (+389)",
    value: CountryCode.Mk,
  },
  {
    label: "Norway (+47)",
    value: CountryCode.No,
  },
  {
    label: "Oman (+968)",
    value: CountryCode.Om,
  },
  {
    label: "Palestinian Territories (+970)",
    value: CountryCode.Pk,
  },
  {
    label: "Papua New Guinea (+675)",
    value: CountryCode.Pg,
  },
  {
    label: "Paraguay (+595)",
    value: CountryCode.Py,
  },
  {
    label: "Peru (+51)",
    value: CountryCode.Pe,
  },
  {
    label: "Pitcairn Islands (+64)",
    value: CountryCode.Pn,
  },
  {
    label: "Poland (+48)",
    value: CountryCode.Pl,
  },
  {
    label: "Portugal (+351)",
    value: CountryCode.Pt,
  },
  {
    label: "Qatar (+974)",
    value: CountryCode.Qa,
  },
  {
    label: "Réunion (+262)",
    value: CountryCode.Re,
  },
  {
    label: "Romania (+40)",
    value: CountryCode.Ro,
  },
  {
    label: "Russia (+7)",
    value: CountryCode.Ru,
  },
  {
    label: "Rwanda (+250)",
    value: CountryCode.Rw,
  },
  {
    label: "Samoa (+685)",
    value: CountryCode.Bl,
  },
  {
    label: "San Marino (+378)",
    value: CountryCode.Sm,
  },
  {
    label: "São Tomé & Príncipe (+239)",
    value: CountryCode.St,
  },
  {
    label: "Saudi Arabia (+966)",
    value: CountryCode.Sa,
  },
  {
    label: "Senegal (+221)",
    value: CountryCode.Sn,
  },
  {
    label: "Serbia (+381)",
    value: CountryCode.Rs,
  },
  {
    label: "Seychelles (+248)",
    value: CountryCode.Sc,
  },
  {
    label: "Sierra Leone (+232)",
    value: CountryCode.Sl,
  },
  {
    label: "Singapore (+65)",
    value: CountryCode.Sg,
  },
  {
    label: "Sint Maarten (+1)",
    value: CountryCode.Sx,
  },
  {
    label: "Slovakia (+421)",
    value: CountryCode.Sk,
  },
  {
    label: "Slovenia (+386)",
    value: CountryCode.Si,
  },
  {
    label: "Solomon Islands (+677)",
    value: CountryCode.Sb,
  },
  {
    label: "Somalia (+252)",
    value: CountryCode.So,
  },
  {
    label: "South Africa (+27)",
    value: CountryCode.Za,
  },
  {
    label: "South Georgia & South Sandwich Islands (+500)",
    value: CountryCode.Gs,
  },
  {
    label: "South Korea (+82)",
    value: CountryCode.Kr,
  },
  {
    label: "South Sudan (+211)",
    value: CountryCode.Ss,
  },
  {
    label: "Spain (+34)",
    value: CountryCode.Es,
  },
  {
    label: "Sri Lanka (+94)",
    value: CountryCode.Lk,
  },
  {
    label: "St. Barthélemy (+590)",
    value: CountryCode.Bl,
  },
  {
    label: "St. Helena (+290)",
    value: CountryCode.Kn,
  },
  {
    label: "St. Kitts & Nevis (+1)",
    value: CountryCode.Kn,
  },
  {
    label: "St. Lucia (+1)",
    value: CountryCode.Lc,
  },
  {
    label: "St. Martin (+590)",
    value: CountryCode.Mf,
  },
  {
    label: "St. Pierre & Miquelon (+508)",
    value: CountryCode.Pm,
  },
  {
    label: "St. Vincent & Grenadines (+1)",
    value: CountryCode.Vc,
  },
  {
    label: "Sudan (+249)",
    value: CountryCode.Sd,
  },
  {
    label: "Svalbard & Jan Mayen (+47)",
    value: CountryCode.Sj,
  },
  {
    label: "Sweden (+46)",
    value: CountryCode.Se,
  },
  {
    label: "Switzerland (+41)",
    value: CountryCode.Ch,
  },
  {
    label: "Taiwan (+886)",
    value: CountryCode.Tw,
  },
  {
    label: "Tajikistan (+992)",
    value: CountryCode.Tj,
  },
  {
    label: "Tanzania (+255)",
    value: CountryCode.Tz,
  },
  {
    label: "Thailand (+66)",
    value: CountryCode.Th,
  },
  {
    label: "Timor-Leste (+670)",
    value: CountryCode.Tl,
  },
  {
    label: "Togo (+228)",
    value: CountryCode.Tg,
  },
  {
    label: "Tonga (+676)",
    value: CountryCode.To,
  },
  {
    label: "Trinidad & Tobago (+1)",
    value: CountryCode.Tt,
  },
  {
    label: "Tristan da Cunha (+2908)",
    value: CountryCode.Ta,
  },
  {
    label: "Tunisia (+216)",
    value: CountryCode.Tn,
  },
  {
    label: "Turkey (+90)",
    value: CountryCode.Tr,
  },
  {
    label: "Turkmenistan (+993)",
    value: CountryCode.Tm,
  },
  {
    label: "Turks & Caicos Islands (+1)",
    value: CountryCode.Tc,
  },
  {
    label: "Tuvalu (+688)",
    value: CountryCode.Tv,
  },
  {
    label: "U.S. Outlying Islands (+1)",
    value: CountryCode.Um,
  },
  {
    label: "Uganda (+256)",
    value: CountryCode.Ug,
  },
  {
    label: "Ukraine (+380)",
    value: CountryCode.Ua,
  },
  {
    label: "United Arab Emirates (+971)",
    value: CountryCode.Ae,
  },
  {
    label: "United Kingdom (+44)",
    value: CountryCode.Gb,
  },
  {
    label: "United States (+1)",
    value: CountryCode.Us,
  },
  {
    label: "Uruguay (+598)",
    value: CountryCode.Uy,
  },
  {
    label: "Uzbekistan (+998)",
    value: CountryCode.Uz,
  },
  {
    label: "Vanuatu (+678)",
    value: CountryCode.Vu,
  },
  {
    label: "Venezuela (+58)",
    value: CountryCode.Ve,
  },
  {
    label: "Vietnam (+84)",
    value: CountryCode.Vn,
  },
  {
    label: "Wallis & Futuna (+681)",
    value: CountryCode.Wf,
  },
  {
    label: "Yemen (+967)",
    value: CountryCode.Ye,
  },
  {
    label: "Zambia (+260)",
    value: CountryCode.Zm,
  },
  {
    label: "Zimbabwe (+263)",
    value: CountryCode.Zw,
  },
];
