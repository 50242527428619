import * as yup from "yup";

import { ICreateEntity, ISharedEntityFields, IShopifyMirroredEntityFields } from "./SharedEntityFields";
import { ISODateString } from "../ISODateString";

// the lib type is kinda sloppy, making this to avoid working with that directly
export type YupValidation<T extends object> = yup.ObjectSchema<yup.Shape<object | undefined, T>>;

const sharedEntityValidation: yup.ObjectSchemaDefinition<ISharedEntityFields> = {
  id: yup.string().required(),
  createdDate: yup.date().required() as unknown as yup.Schema<ISODateString, object>,
  updatedDate: yup.date().required() as unknown as yup.Schema<ISODateString, object>,
  deletedAt: yup.date() as unknown as yup.Schema<ISODateString | undefined, object>,
};

const sharedMirrorValidation: yup.ObjectSchemaDefinition<
  Omit<IShopifyMirroredEntityFields, "shopifyUpdateDate">
> = {
  ...sharedEntityValidation,
  shopifyId: yup.string(),
  receivedFromShopifyDate: yup.date() as unknown as yup.Schema<ISODateString | undefined, object>,
};

interface IEntityValidations<T extends ISharedEntityFields> {
  createValidation: YupValidation<ICreateEntity<T>>;
  editValidation: YupValidation<T>;
}

export function entityValidations<T extends ISharedEntityFields>( // ideally enforce not having "ISharedEntityFields" here
  validations: yup.ObjectSchemaDefinition<ICreateEntity<Omit<T, "shopifyUpdateDate">>>,
  isShopifyMirrorEntity: T extends IShopifyMirroredEntityFields ? true : false
): IEntityValidations<Omit<T, "shopifyUpdateDate">> {
  // ideally enforce not having
  return {
    createValidation: yup.object().shape(validations),
    editValidation: yup.object().shape({
      ...(isShopifyMirrorEntity ? sharedMirrorValidation : sharedEntityValidation),
      ...validations,
    } as any), // very confused why "any" is required here, it does actually work... giving up for now
  };
}
