import { LegacyStack, Text, TextField } from "@shopify/polaris";
import { CurrencyCode } from "@smartrr/shared/shopifyGraphQL/api";
import React from "react";
import styled from "styled-components";

import { CustomerAutocomplete } from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/libs";

import { CustomersTableColumnKeyType } from "..";
import { QuickJumpWrapper } from "../../../AdminSubscriberEngagementRoute/EmailSettings/components/libs/styles";

export const QuickJumpContainer = styled(QuickJumpWrapper)`
  .Polaris-LegacyCard {
    max-width: 282px;
  }
`;

export const DarkCardContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-bottom: 20px;

  .Polaris-Box {
    background-color: #1a1c1d;
    color: #ffffff;
    min-height: 94px;
    width: 300px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  justify-content: space-between;
  padding: 15px;
`;

export const ValueSelectorForCustomersFilter = ({
  field,
  value,
  setValue,
  currency = "USD",
}: {
  field: string;
  value: string | string[] | undefined;
  setValue: (value: string | string[] | undefined) => void;
  currency?: CurrencyCode | string;
}) => {
  switch (field as CustomersTableColumnKeyType) {
    case "totalOrders": {
      return (
        <LegacyStack vertical spacing="extraTight">
          <TextField
            autoComplete="off"
            label=""
            labelHidden
            inputMode="numeric"
            min={0}
            value={Array.isArray(value) ? value.join(";") : value}
            onChange={value => {
              if (value && new RegExp(/^\d*\.?\d*$/).test(value) && +value >= 0) {
                setValue(value);
                return;
              }
              setValue("");
            }}
          />
        </LegacyStack>
      );
    }

    case "totalSpent": {
      return (
        <LegacyStack vertical spacing="extraTight">
          <TextField
            autoComplete="off"
            label=""
            labelHidden
            prefix={currency}
            inputMode="numeric"
            min={0}
            value={Array.isArray(value) ? value.join(";") : value}
            onChange={value => {
              if (value && new RegExp(/^\d*\.?\d*$/).test(value) && +value >= 0) {
                setValue(value);
                return;
              }
              setValue("");
            }}
          />
        </LegacyStack>
      );
    }

    case "customerShopifyId": {
      return (
        <LegacyStack vertical spacing="extraTight">
          <TextField
            autoComplete="off"
            label=""
            labelHidden
            inputMode="text"
            value={Array.isArray(value) ? value.join(";") : value}
            onChange={value => {
              setValue(value);
            }}
          />
        </LegacyStack>
      );
    }
    case "customerName": {
      return (
        <LegacyStack vertical spacing="extraTight">
          <TextField
            autoComplete="off"
            label=""
            labelHidden
            inputMode="text"
            value={Array.isArray(value) ? value.join(";") : value}
            onChange={value => {
              setValue(value);
            }}
          />
        </LegacyStack>
      );
    }
    case "emailOrName": {
      return (
        <LegacyStack vertical spacing="extraTight">
          <CustomerAutocomplete
            selected={Array.isArray(value) ? value : value ? [value] : []}
            handleCustomerChange={setValue}
            textFieldProps={{
              placeholder: "Search...",
            }}
          />
        </LegacyStack>
      );
    }
    default: {
      return (
        <LegacyStack vertical spacing="extraTight">
          <Text variant="bodyMd" as="span">
            Select a filter
          </Text>
        </LegacyStack>
      );
    }
  }
};
