import { Button, LegacyStack, Text } from "@shopify/polaris";
import { ClipboardMinor, EditMajor } from "@shopify/polaris-icons";
import { IMailingAddress, IMailingAddressJson } from "@smartrr/shared/entities/MailingAddress";
import { capitalizeFirstLetter } from "@smartrr/shared/utils/capitalizeFirstLetter";
import { copyToClipboard } from "@smartrr/shared/utils/copyToClipboard";
import { formatAddressInline } from "@smartrr/shared/utils/formatAddressInline";
import { startCase, toLower } from "lodash";
import React from "react";
import styled from "styled-components";

import { useToast } from "../Toast/ToastProvider";

interface IAddressSectionProps {
  address: IMailingAddress | IMailingAddressJson | undefined | null;
  areActionsDisabled: boolean;
  showPhoneNumber?: boolean;

  openModal: () => void;
}

const AddressContainer = styled.div`
  & > .Polaris-LegacyStack > .Polaris-LegacyStack__Item {
    margin-top: 0;
  }

  & > .Polaris-LegacyStack > .Polaris-LegacyStack__Item:first-child {
    margin-top: var(--pc -stack-spacing);
  }
`;

export const AddressSection = ({
  address,
  areActionsDisabled,
  showPhoneNumber = true,
  openModal,
}: IAddressSectionProps) => {
  const { addToast } = useToast();

  return (
    <AddressContainer>
      <LegacyStack vertical spacing="extraTight">
        <LegacyStack distribution="equalSpacing" alignment="center">
          <p id="customer-info__name">{`${address?.firstName ? startCase(toLower(address.firstName)) : ""} ${
            address?.lastName ? startCase(toLower(address.lastName)) : ""
          }`}</p>
          <LegacyStack spacing="loose" alignment="center">
            <Button
              data-test-id="customer-info__edit-default-address-btn"
              id="customer-info__edit-default-address-btn"
              disabled={areActionsDisabled}
              plain
              icon={<EditMajor width="16px" height="16px" />}
              onClick={openModal}
            />
            <Button
              id="customer-info__copy-default-address-btn"
              plain
              icon={<ClipboardMinor width="20px" height="22.5px" preserveAspectRatio="none" />}
              onClick={() => copyToClipboard(formatAddressInline(address), "Address copied", addToast)}
            />
          </LegacyStack>
        </LegacyStack>
        {address?.address1 ? <p id="customer-info__address1">{capitalizeFirstLetter(address.address1)}</p> : null}
        {address?.address2 ? <p id="customer-info__address2">{capitalizeFirstLetter(address.address2)}</p> : null}
        {address?.city ? (
          <p id="customer-info__city-province-zip">
            {capitalizeFirstLetter(`${address?.city}, ${address?.provinceCode || ""} ${address?.zip}`)}
          </p>
        ) : null}
        {address?.country ? (
          <p id="customer-info__country">{capitalizeFirstLetter(`${address.country}`)}</p>
        ) : null}
        {showPhoneNumber ? (
          address?.phone ? (
            <p id="customer-info__phone">{capitalizeFirstLetter(`${address.phone}`)}</p>
          ) : (
            <Text variant="bodyMd" as="span" color="subdued" id="customer-info__no-phone">
              No phone number
            </Text>
          )
        ) : null}
      </LegacyStack>
    </AddressContainer>
  );
};
