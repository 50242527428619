import {
  isPossiblePhoneNumber,
  parsePhoneNumberFromString,
  CountryCode as phoneCountryCode,
} from "libphonenumber-js";

export const validatePhoneNumber = (number: string, country: phoneCountryCode) => {
  return isPossiblePhoneNumber(number.replace("+", ""), country);
};

export const getNumberWithCountryCode = (number: string, country: phoneCountryCode) => {
  return parsePhoneNumberFromString(number, country)?.number;
};

export type { phoneCountryCode };
