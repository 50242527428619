import { Badge, Button, LegacyCard, LegacyStack, Link, Text } from "@shopify/polaris";
import { ClipboardMinor } from "@shopify/polaris-icons";
import { NO_OP_CALLBACK } from "@smartrr/shared/constants";
import { countriesData } from "@smartrr/shared/data/countries";
import { ICustomerRelationshipShallow } from "@smartrr/shared/entities/CustomerRelationship";
import { IMailingAddressCreate, IMailingAddressJson } from "@smartrr/shared/entities/MailingAddress";
import { copyToClipboard } from "@smartrr/shared/utils/copyToClipboard";
import {
  getNumberWithCountryCode,
  phoneCountryCode,
  validatePhoneNumber,
} from "@smartrr/shared/utils/phoneValidation";
import { omit } from "lodash";
import React from "react";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { navigateToCustomerDetails } from "@vendor-app/utils/navigateWithShopInQuery";

import { useModalStatus } from "./hooks/useModalStatus";
import { AddressSection } from "../AddressSection";
import { EditAddressModal } from "../EditAddressModal";
import { useEditAddressModalErrorStore } from "./hooks/useEditAddressModalErrorStore";

export interface IAddressInfoProps {
  address: IMailingAddressJson | undefined | null;
  areActionsDisabled: boolean;
  showPhoneNumber: boolean;
  onUpdateAddress: (address: IMailingAddressJson) => void;
}

interface ICustomerInformationProps {
  customerInfo: ICustomerRelationshipShallow;
  addressInfo?: IAddressInfoProps;
  redirectToShopify?: boolean;
  redirectToCustomerShopify?: (customerId: string | undefined) => void;
  addressTitle?: string;
}

export function CustomerInformationCard({
  addressInfo,
  customerInfo,
  redirectToShopify = false,
  redirectToCustomerShopify,
  addressTitle = "shipping address",
}: ICustomerInformationProps): JSX.Element {
  const { addToast } = useToast();
  const editAddressModal = useModalStatus(false);

  const customerName = `${customerInfo?.firstName ?? ""} ${customerInfo?.lastName ?? ""}`;
  const {
    isModalOpen: isAddressModalOpen,
    closeModal: closeAddressModal,
    openModal: openAddressModal,
  } = useEditAddressModalErrorStore();

  const updateAddress = async (
    addressFormValues: IMailingAddressCreate | undefined,
    inputAddress: IMailingAddressJson | undefined | null
  ) => {
    if (!addressFormValues) {
      return;
    }
    const address: IMailingAddressJson = {
      ...omit(inputAddress, ["zip", "provinceCode"]),
      ...addressFormValues,
    };

    if (address.phone && address.phoneCountryCode) {
      const isPhoneNumberValid = validatePhoneNumber(address.phone, address.phoneCountryCode as phoneCountryCode);
      if (!isPhoneNumberValid) {
        addToast("Invalid Phone number");
        return;
      }
    }

    const updatedPhoneNumber = getNumberWithCountryCode(
      address.phone!,
      address.phoneCountryCode as phoneCountryCode
    );

    const updatedAddress: IMailingAddressJson = {
      ...address,
      phone: updatedPhoneNumber || "",
    };

    addressInfo && addressInfo.onUpdateAddress(updatedAddress);
    editAddressModal.close();
    closeAddressModal();
  };

  return (
    <React.Fragment>
      <LegacyCard>
        <LegacyCard.Section
          title={
            <LegacyStack spacing="baseTight" alignment="center">
              <Text variant="headingMd" as="span">
                Customer
              </Text>
              {customerInfo.hasLoggedIn ? (
                <Badge status="success">Has logged in</Badge>
              ) : (
                <Badge status="new">Has not logged in</Badge>
              )}
            </LegacyStack>
          }
        >
          <Button
            id={
              redirectToShopify
                ? "customer-info__redirect-to-shopify-customer-btn"
                : "customer-info__redirect-to-customer-details-btn"
            }
            plain
            onClick={() =>
              redirectToShopify
                ? redirectToCustomerShopify?.(customerInfo?.shopifyId)
                : customerInfo.shopifyId
                  ? navigateToCustomerDetails(customerInfo.shopifyId, true)
                  : NO_OP_CALLBACK
            }
          >
            {customerName}
          </Button>
        </LegacyCard.Section>
        <LegacyCard.Section title="Contact information">
          <LegacyStack vertical spacing="baseTight">
            <LegacyStack distribution="equalSpacing" alignment="center">
              <Link url={`mailto:${customerInfo?.email}`} id="customer-info__mail-to-customer">
                {customerInfo.email ?? ""}
              </Link>
              <Button
                id="customer-info__copy-email-btn"
                plain
                icon={<ClipboardMinor width="20px" height="22.5px" preserveAspectRatio="none" />}
                onClick={() => copyToClipboard(customerInfo.email, "Email copied", addToast)}
              />
            </LegacyStack>
            {customerInfo.phone == null ? (
              <Text variant="bodyMd" as="span" color="subdued" id="customer-info__no-phone">
                No phone number
              </Text>
            ) : (
              <LegacyStack distribution="equalSpacing" alignment="center">
                <Link url={`tel:${customerInfo.phone}`}>{customerInfo.phone}</Link>
                <Button
                  plain
                  icon={<ClipboardMinor width="20px" height="22.5px" preserveAspectRatio="none" />}
                  onClick={() => copyToClipboard(customerInfo.phone, "Phone copied", addToast)}
                />
              </LegacyStack>
            )}
          </LegacyStack>
        </LegacyCard.Section>
        {addressInfo ? (
          <LegacyCard.Section title={addressTitle.toLocaleUpperCase()}>
            <AddressSection
              address={addressInfo.address}
              areActionsDisabled={addressInfo.areActionsDisabled}
              openModal={editAddressModal.open}
              showPhoneNumber={addressInfo.showPhoneNumber}
            />
          </LegacyCard.Section>
        ) : null}
      </LegacyCard>
      {addressInfo ? (
        <EditAddressModal
          title={`Edit ${addressTitle}`}
          open={editAddressModal.isOpen || isAddressModalOpen}
          address={addressInfo.address}
          countriesList={countriesData}
          updateAddress={value => updateAddress(value, addressInfo.address)}
          closeModal={() => {
            editAddressModal.close();
            closeAddressModal();
          }}
        />
      ) : null}
    </React.Fragment>
  );
}
