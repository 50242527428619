import React from "react";

export const activatorButton = (countryCode: string, toggle: () => void) => {
  return (
    <button
      onClick={toggle}
      className="Polaris-Button"
      type="button"
      aria-controls="Polarispopover1"
      aria-owns="Polarispopover1"
      aria-expanded="true"
      style={{ height: "35px" }}
    >
      <span className="Polaris-Button__Content">
        <span className="Polaris-Button__Text">
          <span className="Polaris-Thumbnail Polaris-Thumbnail--sizeExtraSmall" style={{ border: "none" }}>
            <img
              alt="United States"
              src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode}.svg`}
            />
          </span>
        </span>
        <span className="Polaris-Button__Icon">
          <div className="">
            <span className="Polaris-Icon">
              <span className="Polaris-Text--root Polaris-Text--visuallyHidden"></span>
              <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                <path d="M13.098 8h-6.196c-.751 0-1.172.754-.708 1.268l3.098 3.432c.36.399 1.055.399 1.416 0l3.098-3.433c.464-.513.043-1.267-.708-1.267Z"></path>
              </svg>
            </span>
          </div>
        </span>
      </span>
    </button>
  );
};
