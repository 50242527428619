import { CountryCode as TCountryCode, CurrencyCode as TCurrencyCode } from "./shopifyGraphQL/api";
export type CurrencyCode = Uppercase<keyof typeof TCurrencyCode>;
export type CountryCode = Uppercase<keyof typeof TCountryCode>;
export const CountryCodes: Set<string> = new Set(Object.values(TCountryCode)) as any;

export function isCurrencyCode(value: any): value is TCurrencyCode {
  return Object.values(TCurrencyCode).includes(value);
}

export function toCurrencyCode(value: any): TCurrencyCode {
  if (!isCurrencyCode(value)) {
    throw new Error(`Unknown currency code: ${value}`);
  }
  return value;
}
