import { useState } from "react";

export const useModalStatus = (initial: boolean) => {
  const [isOpen, setIsOpen] = useState(initial);

  return {
    isOpen,
    open() {
      setIsOpen(true);
    },
    close() {
      setIsOpen(false);
    },
  };
};
